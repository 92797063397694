<template>

<v-container class="fill-height">
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card v-if="showForm" class="elevation-12">
        <v-card-title class="blue lighten-1" >
            <span class=" flex text-center headline">
          {{ this.rtl ?
          "انتخاب رمز ورود جدید"
               :
               "NEW PASSWORD SELECTION"
          }}
              </span>
        </v-card-title>
    <v-card-text>
        <v-form v-model="isFormValid">
    
          <v-text-field 
              id="password" 
              :label="passwdLabel" 
              :type="showPass ? 'text' : 'password' "
              name="password" 
              v-model="password" 
              :rules="passwordRules"
              prepend-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
            >
          </v-text-field>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn 
              :disabled="!isFormValid" 
              medium 
              @click="changePassword( password)" 
              color="#64B5F6">
            {{ this.rtl ?
            "بفرست"
              :
              "Submit"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
<v-card v-else class="elevation-12">
        <v-card-title>
          {{ this.rtl ?
          this.msgFarsi
          :
          this.msgEng
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium color="#64B5F6"  href="/ui/login">
            {{ this.rtl ?
            "بازگشت"
            :
            "RETURN"
          }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
          </v-row>
          </v-container>

</template>

<script>

import axios from 'axios'
import Vuex from 'vuex'

export default {
  name: 'PasswordReset',
  data: function () {
    return {
      studentId: null,
      reset_code: null,
      isFormValid: false,
      dialog: false,
      accessCode: '',
      msgFarsi: null,
      msgEng: null,
      showForm: false,
      showError: false,
      password: null,
      showPass: false,
      passwordRules: [
        v => {
          if (v) {
            return true
          } else {
            return false
          }
        }
      ],
      backendHost: process.env.NODE_ENV === 'development' ? 'http://localhost:3085'  : ''
    }
  },
  watch: {
  },
  async mounted() {
    this.studentId = this.$route.params.id
    this.reset_code = this.$route.query.reset_code
    let res
    try {
       res = await axios.get(`${this.backendHost}/api/students/${this.studentId}/verify?reset_code=${this.reset_code}`, this.headerConfig)
    } catch (e) {
      res = e

    }
    if ( res.status === 200 ) {
      this.showForm = true
    } else {
      this.showForm = false
      this.showError = true
      this.msgFarsi =
          "لینک انتخاب رمز صحیح نیست"
      this.msgEng = 
          "Your password reset link is not valid"
    }

  },
  computed: {
    ...Vuex.mapState([
      "rtl",
    ]),
    passwdLabel() {
      if (this.rtl)
        return "رمز ورود" 
      else
        return "password"
    },

  },
  methods: { 
    ...Vuex.mapActions("auth", [
      "login",
    ]),
    async openDialog () {
      this.dialog = true
      this.$vuetify.rtl = false

    },
    async changePassword() {
      console.log("start change password")
      let res
      try {
        res = await axios.put(`${this.backendHost}/api/students/${this.studentId}/password_reset?reset_code=${this.reset_code}`, { code: this.password }, this.headerConfig)
      } catch (e) {
        res = e

      }

      console.log(res)
      console.log("HI")
      this.showForm = false
      if (res.status === 200) {
        this.msgFarsi = "تغییر رمز ورود شما با موفقیت انجام شد"
        this.msgEng = "password changed successfully"
      } else {
        this.msgFarsi = "متاسفانه تغییر رمز ورود شما موفقیت آمیز نبود"
        this.msgEng = "Was not able to change password"
      }
    }

  }
}
</script>
