<template>
    
  <v-app id='app'>
    <v-app-bar
     class="blue darken-1"
    >
    <!--
    <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Title</v-toolbar-title>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
               <v-icon> {{ mdiViewHeadline }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
               <v-icon> {{ mdiViewHeadline}} </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    -->
   

    <!--
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>
          <v-spacer></v-spacer>

    -->
    <!-- 
      <v-menu
              left
              bottom
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          >
                          <v-icon> {{ mdiMenu }}  </v-icon>
                  </v-btn>
              </template>
              <v-list color="blue lighten-3">
                    <v-list-item
                    v-for="n in 5"
                    :key="n"
                    @click="() => {}"
                    >
                    <v-list-item-title>Options {{ n }}</v-list-item-title>
                    </v-list-item>

                 <v-list-item-group>
                 <v-list-item style="width:100%" @click="goToDarses" left large class="mt-1" color="blue lighten-2">
                       <template v-slot:default>
                          <h2>
                          <v-icon >  
                             {{ mdiViewQuilt }} 
                          </v-icon>
                             درسها
                          </h2>
                       </template>
                       </v-list-item>
                  <v-list-item style="width:100%" @click="goToAllDarses" left large class="mt-1"  color="blue lighten-2" >
                       <template v-slot:default>
                          <h2>
                          <v-icon >  
                             {{ mdiViewHeadline }} 
                          </v-icon>
                             کلمه ها
                          </h2>
                       </template>
                    </v-list-item>

                 </v-list-item-group>
                 </v-list>
      </v-menu>
                 -->
  <v-img
      class="mx-2"
      src="/shekkar/shekkar_white.png"
      @click="goToHome"
      max-height="40"
      max-width="40"
      contain
    ></v-img>
    <v-app-bar-title class="white--text text-no-wrap" >

      
      {{ this.isEn ? 
      "SHEKKAR FARSI SCHOOL" : 
      &nbsp;&nbsp;&nbsp;
      "مدرسه فارسی شکر"
      &nbsp;&nbsp;&nbsp;
      }}
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <!--
    <v-btn class="me-auto" text href="/">
           <v-icon>mdi-open-in-new</v-icon> 
        </v-btn>-->
        <v-btn class="white--text me-auto" @click="toggleLang" outlined  >
           {{  this.nextLang }} 
        </v-btn>
           <v-divider v-if="studentId" vertical inset color="white" :thickness="5" class="mx-5"> </v-divider>
             <v-avatar v-if="student.imgProfile">
                <v-img  class="mx-auto" height="65px" width="65" :src='student.imgProfile'>
                </v-img>
             </v-avatar>
        <v-btn v-if="studentId" class="white--text me-auto" @click="userLogout" text  >
               <v-icon v-if="studentId"> {{ mdiLogoutVariant }} </v-icon>
        </v-btn>
    </v-app-bar>

    
    <v-main  :style="`background-image:url('/images/shekarksksj.png')`" 
    class="fill-height bg-color "
    >
      <router-view style="padding:0px;" @darsId="onClickChild"></router-view> 
    </v-main>
  </v-app>
</template>

<script>
//import Main from './components/Main';
import pn from 'persian-number';
//import { mdiDotsVertical  } from '@mdi/js';
import { mdiMenu  } from '@mdi/js';
import { mdiLogoutVariant  } from '@mdi/js';
import Vuex from 'vuex'
import { mdiViewHeadline   } from '@mdi/js';
import { mdiViewQuilt   } from '@mdi/js';

export default {
  name: 'App',

  components: {
    //Main,
  },
  computed: {
    ...Vuex.mapState([
      "rtl",
    ]),
     ...Vuex.mapState("auth",[
      "studentId",
    ]),
     ...Vuex.mapState("student",[
      "student",
    ]),
  rtl:{
    get: function(){
      return this.$store.state.rtl;
    },
    set: function(newRtl){
      this.$store.commit('rtl',newRtl);
    }
  },
    isEn() {
      return this.language === "English"
    },
    nextLang() {
      if (this.language === "English") {
        return "فارسی"
      } else {
        return "English"
      }
    }
  },
  mounted() {
    this.setRTL();
    window.onorientationchange = function () {
        var orientation = window.orientation;
            switch(orientation) {
                case 0:
                case 90:
                case -90: window.location.reload();
                break; }
    };
  },
  methods: {
    ...Vuex.mapActions("auth", [
      "logout"
    ]),
    setRTL () {
      this.$vuetify.rtl = this.rtl 
    },
    toggleLang() {
      if (this.language === "English") {
        this.language = "فارسی"
        this.rtl = true
        this.setRTL()
      } else {
        this.language = "English"
        this.rtl = false
        this.setRTL()
      }

    },
   userLogout () {
      console.log("Logging out user")
      this.logout()
      this.$router.push({name: 'login'   })
    },
    pn(num) {
      return pn.convert(num)
    },
    onClickChild (value) {
      this.darsIdCopy = value
    },
    async goToHome() {
      this.$router.push({name: 'land' })
    },
    async goToAllDarses() {
      this.$router.push({name: 'all_darses' , params: { page:1 }  })
    },
    async goToDarses() {
      this.$router.push({name: 'darses',params: {darsId:'latest'}})
    },
  },

  data: () => ({
    //darsIdCopy: '' ,
    //mdiDotsVertical ,
    language: "فارسی", 
    //language: "English",
    mdiMenu ,
    mdiViewHeadline,
    mdiViewQuilt,
    mdiLogoutVariant,
    drawer: false, 
  }),
};
</script>


<style >
.bg-image {
   background-repeat: repeat;
   background-position: center center;
   background-attachment: fixed;
   background-size: cover;
 }
 .bg-color {
   background-color: #e9dcc6;
 }
 .v-app-bar-title__content{
  width: 250px !important;
}

</style>
