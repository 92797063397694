import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/Auth';
import student from './modules/Student';
import harf from './modules/Harf';
import kelas from './modules/Kelas';
import admin from './modules/Admin';
//import createLogger from 'vuex/dist/logger';


Vue.use(Vuex);

const state = {
   rtl: true,
}
const mutations = {
   rtl(state, newRtl) {
      state.rtl = newRtl
   },
}

const getters = {}
export default new Vuex.Store({
   modules: {
      auth, 
      student,
      harf,
      kelas,
      admin
   },
   state,
   getters,
   mutations,
   //strict: debug,
   //plugins: debug? [ createLogger() ] : [],
})

