//import { Shape } from 'paper/dist/paper-core';

var paper = require('paper');
const lodash = require('lodash')
window.debugObj = { "hasInfo": true}

const debugRendering = false

const cons = {
  vertMargin : 25,
  horizMargin : 50 

}

const calcDims = ( rp, numPhis, numAtps, minS) => {
   if (debugRendering ) console.log(rp)
  let phiSide = Math.min ( Math.floor(rp.avaiHeight/(numPhis + 1)/7) * 7 , minS)
  phiSide = Math.floor(phiSide/7)*7
  const phpRow = phiSide + 2 * php.phiSpacing

  //window.debugObj["phiSide"] = phiSide

  const allAtpHeight = rp.avaiHeight - phpRow 
  let atiSide = Math.min ( Math.floor((allAtpHeight/(numAtps) - atp.atpBetRow - 2* atp.atpSpacing ) )  , 60) // atp.atpBetRow + atp.atpSpacing 
  atiSide = Math.floor(atiSide/5)*5
  //const atpRow = atiSide + 2 * atp.atpSpacing
  window.debugObj.atiSide = atiSide

 // canvas.height = phpRow + numAtps * atpRow + 100  
  if (debugRendering ) console.log( `${numPhis} Phis w side: ${phiSide} `)
  if (debugRendering ) console.log( `${numAtps} Atis w side: ${atiSide}` )
 // console.log("Canvas Height adjusted to " + canvas.height)
  return { phiSide,atiSide }
}


// render Pane
class rp {
  //static vertMargin = 25
  //static horizMargin = 50 
  constructor ( visualize = false  ) {
    const topRight = new paper.Point( 
      paper.view.size._width - cons.horizMargin, cons.vertMargin 
    );
    const bottomLeft = new paper.Point( 
      cons.horizMargin, paper.view.size._height - cons.vertMargin 
    );
    if ( visualize ) {
      const topLeft = new paper.Point(
        cons.horizMargin, cons.vertMargin
      );

      const circ1 = new paper.Shape.Circle(topRight, 10)
      circ1.strokeColor = 'black'
      const circ2 = new paper.Shape.Circle(bottomLeft, 10)
      circ2.strokeColor = 'black'
      const circ3 = new paper.Shape.Circle(topLeft, 10)
      circ3.strokeColor = 'black'
    }

    this.rectTR = topRight
    this.rectBL = bottomLeft
    this.avaiWidth = (this.rectTR.x -  this.rectBL.x) 
    this.avaiHeight = (this.rectBL.y -  this.rectTR.y)
    console.log("avaiWidth: " + this.avaiWidth)
    console.log("avaiHeight:" +  this.avaiHeight)
    //this.phpSide = 
   }

 //setPhiSide(numPhis) {
 //  this.phiSide = Math.min ( Math.floor(this.avaiWidth/(numPhis + 1)/5) * 5 , 60)
 //  this.phiSide = Math.floor(this.phiSide/5)*5
 //  this.phpRow = this.phiSide + 2 * php.phiSpacing
 //  this.phiPerRow = numPhis
 //  console.log( "Phi Side should be: " + this.phiSide + ", row is: " + this.phpRow)
 //}

  //setAtiSide( numAtps ) { 
  //  let phpTR = this.rectTR 
  //  let phpBL = phpTR.subtract(this.avaiWidth, - this.phpRow )
  //  this.avaiHeight = this.rectBL.y - phpBL.y 
  //  console.log("Avai Height " + this.avaiHeight )
  //  this.atiSide = Math.min ( Math.floor((this.avaiHeight/(numAtps) - atp.atpBetRow - 2* atp.atpSpacing ) )  , 60) // atp.atpBetRow + atp.atpSpacing 
  //  this.atpRow = this.atiSide + 2 * atp.atiSpacing
  //  console.log( "Ati Side should be: " + this.atiSide )
  //}

}

// placeHolderItem
class phi { 

  // static variables
  constructor ( topRight, bottomLeft , destKey) {

   //console.log("Hala")
   //console.log(topRight)
   //console.log(bottomLeft)
   this.phiRect = new paper.Path.Rectangle(topRight, bottomLeft )
   this.origBound = this.phiRect.bounds
   //console.log(this.phiRect.bounds)
   this.aTile = null
   this.phiRect.strokeColor = 'black'
   this.phiRect.fillColor = 'white'
   this.overwrite = false
   this.destKey = destKey
   //harekat
   if ( destKey === '2' || destKey === 'a' || destKey === 'o' || destKey === 'e' ) {
      this.overwrite = true
    }
  }
  getPhiRect() {
    return this.phiRect
  }
}
// placeHolderPane
class php {
    

    static phiSpacing = 10
    static phiGutter = 3 

    constructor ( rp, TR, phiSide, numTiles, perRow ) {
      //console.log("Num Tiles " + numTiles)
      //
      this.tilesPerRow = perRow  
      this.numRows =  Math.ceil(numTiles/perRow)
      this.phiSide = phiSide
      this.phpRow = phiSide + 2 * php.phiSpacing
      this.darsDone = false
      //this.phpRow = rp.phpRow

      this.topRight = TR 
      window.debugObj["rows"] = this.numRows
      this.bottomLeft = TR.subtract(rp.avaiWidth, - this.phpRow * this.numRows ) 
      window.debugObj["BL"] = this.bottomLeft

      this.phpRect = paper.Path.Rectangle(
        this.topRight, this.bottomLeft
      )
     // console.log(topRight)
     // console.log(bottomLeft)
      this.phiRowTR = this.topRight.subtract ( php.phiSpacing, -php.phiSpacing )
      this.phpRect.fillColor = '#d3d3d3'
      this.phInsts = []
      this.numRows = 3
     //var text = new paper.PointText(this.bottomLeft);
     //text.justification = 'center';
     //text.fillColor = 'black';
     //text.content = `${phiSide}`;
    }
    getPhiTopRight(loc) { // todo: add row 
      // TODO : implement Row
      const locX = (loc - 1) % this.tilesPerRow + 1 
      const locY = (loc - locX)/this.tilesPerRow
      //console.log(locX,locY)
      let tr = this.phiRowTR.subtract( (locX-1)* ( this.phiSide + php.phiGutter), -locY * this.phpRow )
      //console.log(tr)
      return tr
    }
    getPhiBottomLeft(loc)  { // todo : add row
      // TODO : implement Row
      const locX = (loc - 1) % this.tilesPerRow  + 1 
      const locY = (loc - locX)/this.tilesPerRow
      const bl =  this.phiRowTR.subtract( locX*  this.phiSide + ( locX-1)* php.phiGutter, -(locY+1) * this.phiSide )
      //console.log(bl)
      return bl
    }
    addPhInsts(phInsts ) {
      this.phInsts = this.phInsts.concat(phInsts)
    }
    renderPlaceHolderInsts (resize) {
      let startingTopRight = this.phiRowTR
      //let prevStartingTopRight
      for ( let [idx,plh] of this.phInsts.entries() ) {
        //console.log("--------" + idx)
        if ( plh.aTile === null ) {
          const newBound = new paper.Rectangle(this.getPhiTopRight(idx+1), this.getPhiBottomLeft(idx+1))
          //console.log(plh.phiRect.bounds)
          //console.log(newBound)
          plh.phiRect.bounds = newBound
          startingTopRight = startingTopRight.subtract(this.phiSide, 0) 
        } else { 
          const tile = plh.aTile.group
          //window.tile = tile
          //console.log(tile.bounds) //console.log(tile.firstChild.bounds)
          //console.log(tile.lastChild.bounds)
          ////////  MOHEM const scaling =  this.phiSide / tile.lastChild.bounds.height
          //const scaling = 1
          //console.log ( "Scaling by: " + this.phiSide / tile.lastChild.bounds.height )
          //console.log ( "phiSide: " + this.phiSide )
          //console.log ( "tiles last child: " + tile.lastChild.bounds.height )
          //console.log(newBound)
          if ( resize ) {
          const nextPlh = this.phInsts[idx+1]
          if ( nextPlh && nextPlh.aTile && nextPlh.overwrite ) {
             const newBound = new paper.Rectangle(startingTopRight, startingTopRight )
            tile.bounds = newBound 
            if (debugRendering ) console.log("Skipping " + plh.aTile.destLetter)
            continue
          }

              if (debugRendering ) console.log(`rendering ${plh.destKey} `)
           //if ( plh && plh.overwrite ) {
           //  console.log(`plh destkey ${plh.destKey} overWrite`)
           //  //startingTopRight = prevStartingTopRight
           //}
             const newBound = new paper.Rectangle(startingTopRight, startingTopRight.subtract(tile.children[2].bounds.width , -tile.children[2].bounds.height ))
             //const newBound = new paper.Rectangle(startingTopRight, startingTopRight.subtract(this.phiSide , -this.phiSide ))
             //console.log("Resize")
             //console.log(tile.bounds)
             //console.log(newBound)
            tile.bounds = newBound 
             //prevStartingTopRight = startingTopRight
             startingTopRight = startingTopRight.subtract(tile.bounds.width, 0)
          }
        }

      }
    }
}


// alphabet tile pane
class atp {
  // static variables
  //static atiSide = 80
  //static tileRow = 225
  //static tileSpacingL = 10

  // external margins
  static rightMargin = 50
  //static topMargin = 30

  //static atiSide = 50 
  static atpBetRow = 8 
  // spacing around ati
  static atpSpacing = 3 
  //static atpRow = atp.atiSide + 2 * atp.atpSpacing
  static atiGutter = 2 
  constructor (TR , atiSide, numAlphs ) {
    this.atiSide = atiSide
    this.atpRow = this.atiSide + 2 * atp.atpSpacing
    const topRight = TR.add(0, atp.topMargin)  
    const bottomLeft = topRight.subtract( numAlphs * this.atiSide + ( numAlphs -1 ) * atp.atiGutter + 2 * atp.atpSpacing, -this.atpRow  )
    this.atpRect = paper.Path.Rectangle(
      topRight, bottomLeft
    )
    window.atpRect = this.atpRect

    this.atpRect.strokeColor = 'red'
    this.atpRect.fillColor = 'red'
    this.atiRowTR = topRight.subtract ( atp.atpSpacing, -atp.atpSpacing )
    this.atInsts = []
  }
  getAtiTopRight(loc) { 
    return this.atiRowTR.subtract( (loc-1)* ( this.atiSide + atp.atiGutter), 0 )
  }
  getAtiBottomLeft(loc)  {
    return this.atiRowTR.subtract( (loc)* ( this.atiSide + atp.atiGutter) - atp.atiGutter,  -this.atiSide )
  }
  addAtInsts(atInsts ) {
    this.atInsts = this.atInsts.concat(atInsts)
  }
  
}

// alphabet tile instance
class ati {
  static rasterMargin = 5
  constructor ( phiSide, topRight, bottomLeft, atiSide, phList, letter, destLetter) {
    //console.log(topRight)
    //console.log(bottomLeft)
    this.atiRect = new paper.Path.Rectangle(topRight, bottomLeft )
    this.atiRect.strokeColor = 'red'
    this.atiRect.fillColor = 'white'
    
    let alpI = document.getElementById(letter)
    if (debugRendering ) console.log(`Getting ${letter}`)
    //console.log(` ati letter: ${letter}`)
    if ( alpI ) {
       //console.log(alpI)
    } else {
       console.log('Raster NULL, ati will not work')
    }

     var raster = new paper.Raster(alpI)  
     raster.position = this.atiRect.position 
     raster.scale((atiSide-ati.rasterMargin)/raster.size.height, (atiSide-ati.rasterMargin)/raster.size.height);

     if ( destLetter ) {
       let destAlpI = document.getElementById(destLetter)
       var destRaster = new paper.Raster(destAlpI)  
       destRaster.position = this.atiRect.position 
       //destRaster.scale( (atiSide-ati.rasterMargin)/raster.size.height, (atiSide-ati.rasterMargin)/raster.size.height);
       destRaster.scale( (phiSide)/raster.size.height, (phiSide)/raster.size.height);
       //console.log(destRaster)
       destRaster.visible = false
       this.destLetter = destLetter
     }

     const group = new paper.Group([this.atiRect, raster, destRaster])
     
     this.group = group
     this.origin = group.position
     this.freeze = false
     // mouse is released, resolve the moved tile either to phi or back to its place
     this.resolve = false
     this.resolved = false
     this.resolving = false
     this.resolvingTarg = null
     this.resolvingPhi = null
     this.ph = phList
     this.group = group

     // For moving the alphaTile
     group.onMouseDrag = (event) => { 
      if ( this.freeze ) {
        return
      }
       group.position = group.position.add(event.delta)
       window.gr = this
     }

     // Upon release of mouse, resolve alphaTile
     group.onMouseUp = ( ) => {
       if ( !this.freeze )
       this.resolve = true 
     }
  }
  animate(firstUnresolvedPh) {
    const verbose = true

    if ( this.resolve ) {
      this.resolve = false
      for ( let [idx,ph] of this.ph.entries() ) {
        if ( ph.aTile ) { 
          if ( verbose )
          if (debugRendering ) console.log(`placeholder at index ${idx} resolved with tile ${ph.aTile.destLetter}`)
          // these are already resolved placeholders
          continue
        } 
        
        // tile is released in the right place
        if ( ph.phiRect.contains(this.group.position) && ph === firstUnresolvedPh) {
          this.resolvingTarg = ph.phiRect.position 
          this.resolvingPhi = ph 
          if (verbose)
            if (debugRendering ) console.log(`placeholder at index ${idx} will now resolve destLetter ${this.destLetter} `)
          //window.ph = ph
          break
        }  
      }
      // Either final destination is found or move back to origin
      this.resolvingTarg = this.resolvingTarg || this.origin
    }

    let vector = this.resolvingTarg.subtract(this.group.position) 
    let step = vector  
    //console.log('vector length: ' + vector.length )
    if ( vector.length > 20 ) {
      //console.log( 'step is: ' +step)
      step = step.divide(10)
      //console.log( 'step is: ' +step)
      step = step.floor()
      //console.log( 'step is: ' +step)
    } else { 
      step = vector
    }
    this.group.position = this.group.position.add(step)
    if ( this.group.position.equals(this.resolvingTarg) ) {
      this.resolvingTarg = null
    }
    if ( this.resolvingPhi && this.group.position.equals(this.resolvingPhi.phiRect.position) ) {
      this.resolved = true
      this.group.children[0].visible = false
      this.group.children[1].visible = false
      this.group.children[2].visible = true
      //this.group.children[2]
      //this.group[1].visible = false
      this.resolvingPhi.aTile = this
      //this.resolvingPhi.aTile.scale(1.3)
      //this.ph.group.bounds = this.group.lastChild.bounds 
      this.resolvingPhi.phiRect.visible = false
      this.freeze = true
      //console.log("resolved")
      //console.log(this)
    } else if ( this.group.position.equals(this.origin))  {
      this.group.children[0].visible = true
      this.group.children[1].visible = true
      this.group.children[2].visible = false
      this.resolved = false
      if ( this.resolvingPhi ) {
        this.resolvingPhi.aTile = null
        this.resolvingPhi.phiRect.visible = true
      }
      if (debugRendering ) console.log(" non resolved")
      //console.log(this.group)
      //console.log(this.ph.phiRect)
      this.resolvingPhi = null
      //this.ph.group.bounds = this.ph.origBound
    }
  }
}

//const createMic = (micPosition, callBack) => {
//   console.log(`create mic`)
//   let mic = document.getElementById("mic")
//   let micRaster = new paper.Raster(mic)  
//   micRaster.position = micPosition  
//   micRaster.strokeColor = "yellow"
//   //window.earRaster = earRaster
//   micRaster.onMouseDown= async ( ) => {
//      await callBack() 
//   }
//}


const createEar = ( earPosition, audio, side ) => { 
  if ( document.getElementById(audio) && document.getElementById(audio).children.length == 0 ) return
  if (debugRendering ) console.log(`create ear for ${audio}`)
  let ear = document.getElementById("ear")
  let earRaster = new paper.Raster(ear)  
  earRaster.position = earPosition  
  earRaster.strokeColor = "black"
  earRaster.scale(Math.min(1,side/ear.height))
  //window.earRaster = earRaster
  earRaster.onMouseDown= async ( ) => {
     //console.log("==================> start")
    earRaster.scale(1.25) 
    await document.getElementById(audio).load()
    await document.getElementById(audio).play()
    setTimeout(() => {
      earRaster.scale(0.8)
    }, 200)
     //console.log("==================> end")
  }
}

const createPlaceHolderPane = ( renderArea, TR, phiSide, harfForms,  perRow, darsKalameh) => {

  //var phPane = new php( renderArea, TR.add(0,renderArea.phiSide), harfForms.length )
  window.debugObj["TR"] = TR
  var phPane = new php( renderArea, TR, phiSide, harfForms.length, perRow )
  //console.log(phPane)
  
   //console.log(createMic)
   //console.log(callBack)
  //const micPosition = phPane.topRight.add( php.phiSpacing + renderArea.phiSide/4 , -php.phiSpacing - renderArea.phiSide/2 ) 
  //createMic(micPosition, callBack);
  const earPosition = phPane.topRight.add( php.phiSpacing + phiSide/4 , php.phiSpacing + phiSide/2 ) 
  // the commented line was an attempt to show ear only when kalamehAudio exists
  // it failed because although it was working fine on mounted, didnt work with arrow keys
  //if (darsKalameh && document.getElementById(darsKalameh).firstChild.attributes['src'].value) { 
  if (darsKalameh ) { 
    createEar(earPosition, darsKalameh, phiSide);
  }

  let phInsts = []
  let idx = 0
  for ( idx of harfForms.keys() ) {
     
    const phInst = new phi(phPane.getPhiTopRight(idx+1), phPane.getPhiBottomLeft(idx+1) , harfForms[idx])
    phInsts.push(phInst )
    idx++
  }
   phPane.addPhInsts(phInsts)
   return phPane 
}

const createAlphatilePane = (rp, anchorTopRight, atiSide, harf, phPane , kalamehHarfForms) => {

  let topRight = anchorTopRight.add(0, atp.atpBetRow ) 
  //if ( harf != "faseleh" ) {
    //  const audio = harf.match(/([a-z]*)_/).[1]
    //  createEar(topRight.add( atp.atpSpacing + atp.atiSide/4  , atp.atpSpacing + atp.atiSide/2   ) ,audio)
    //}

    // to make sure its a primary
    let atPane = new atp( topRight, atiSide, harf['harfGroups'].filter(a => { return  a === 'self' }).length  )
    let idx2=1
    //temp hack. for now use harfSounds[0]
    const audio = harf['harfSounds'][0] 
    //console.log(harf['harfSound'])
    createEar(topRight.add( atp.atpSpacing + atPane.atiSide/2  , atp.atpSpacing + atPane.atiSide/2   ) ,audio)

    if (debugRendering ) console.log("============================= Rendering row for harf ="  )
    if (debugRendering ) console.log(harf['harfKeys'][0])
    //console.log("============================="  )

    const kalamehHarfPrimaryForms = kalamehHarfForms.reduce( (tot,hf) => {
       //console.log(hf)
       const idx = harf['harfKeys'].findIndex( a => a === hf )
       if ( idx < 0 ) { 
         //console.log(" ... irrelevant")
         tot.push(null)
         return tot
       }
          //console.log(harf['harfGroups'][idx])
          //console.log(harf['harfForms'][idx])
       if ( harf['harfGroups'][idx] === 'self' ) { 
          tot.push (harf['harfKeys'][idx])
          return tot
       } 
       const primaryHarf = harf['harfKeys'].find( a => { 
        //console.log(a)
        //console.log(a.length)
        //console.log(harf['harfGroups'][idx])
        //console.log(harf['harfGroups'][idx].length)
        //console.log( a == harf['harfGroups'][idx])
          return  a == harf['harfGroups'][idx] 
       } )
      //console.log(harf['harfKeys'])
      //console.log(harf['harfGroups'][idx])
      //console.log(primaryHarf)
       tot.push ( primaryHarf)
       return tot
    },[])

   if (debugRendering ) console.log(' Kalameh Harf Froms: ' + kalamehHarfForms)
   if (debugRendering ) console.log(' Kalameh Harf primary forms ' + kalamehHarfPrimaryForms)

    for ( let [idx,harfKey] of harf['harfKeys'].entries() ) {
      //const harfGroup = harf['harfGroups'][idx]
      //const harfForm = harf['harfForms'][idx]
    //console.log(harfKey)
    //console.log(harfGroup)

      if ( harf['harfGroups'][idx] != 'self' ) continue 
      const occurances = kalamehHarfForms.reduce( (tot,elem,harfIndex) => { 
        //console.log('--->' + elem )
        if ( kalamehHarfPrimaryForms[harfIndex] === harfKey ) { 
          tot.push(harfIndex)
          //console.log('-------> direct' )
        } 
        return tot 
      } , [] )
      if (debugRendering ) console.log(`occurenace of ${harf['harfKeys'][0]} are ` + occurances)

      let atInst
      let destKey = null
       const plHoldersArray = occurances.map(a => phPane.phInsts[a])
      if (occurances.length === 0) {

        //atInst = new ati( atPane.getAtiTopRight(idx2) , atPane.getAtiBottomLeft(idx2), atPane.atiSide, plHoldersArray, harfForm, destKey  )
        if (debugRendering ) console.log(`ati for letter ${harfKey} NO DEST`)
        atInst = new ati(phPane.phiSide, atPane.getAtiTopRight(idx2), atPane.getAtiBottomLeft(idx2), atPane.atiSide, plHoldersArray, harfKey, destKey)
        atPane.addAtInsts([atInst])
        //atInst = new ati( atPane.getAtiTopRight(idx2) , atPane.getAtiBottomLeft(idx2), atPane.atiSide, plHoldersArray, harfForm, destKey   )
        atInst = new ati(phPane.phiSide, atPane.getAtiTopRight(idx2), atPane.getAtiBottomLeft(idx2), atPane.atiSide, plHoldersArray, harfKey, destKey)
        atPane.addAtInsts([atInst])
        idx2++
      } else {
        //console.log( harf + " Occurs:" ) 
        if (debugRendering ) console.log( occurances)
        for (let occurance of occurances.reverse()) {
          let destKey = kalamehHarfForms[occurance]
          //console.log(destKey)

        if (debugRendering ) console.log(`ati for letter ${harfKey} ${destKey}`)
        // harekat : creating combined letter
          if ((destKey === '2' || destKey === 'a' || destKey === 'o' || destKey === 'e' ) && occurance > 0) {
            // do something here
            if (kalamehHarfForms[occurance - 1] === '2') {
              destKey = kalamehHarfForms[occurance - 2] +  kalamehHarfForms[occurance - 1] + destKey
            } else {
              destKey = kalamehHarfForms[occurance - 1] + destKey
            }

          }
        if (debugRendering ) console.log(`ati for letter ${harfKey} ${destKey}`)
        atInst = new ati(phPane.phiSide, atPane.getAtiTopRight(idx2), atPane.getAtiBottomLeft(idx2), atPane.atiSide, plHoldersArray, harfKey, destKey)
        atPane.addAtInsts([atInst])
        }
        idx2++


      }
    }
  return atPane
}

const getRenderArea = () => {
  const renderPane = new rp(false );
  return renderPane
}

//const getCanvasHeightDarses = (darsHoroof) => {
//  return 2 * rp.vertMargin + darsHoroof.length * 50 
//}

const shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
      }

const installTileAnimator = (paper,phPane, atPanes) => {
   paper.view.onFrame = async () => { 

      const atInsts = lodash.flatten(atPanes.map(a => a.atInsts) )
      const tile = atInsts.find(t => { 
         return t.resolve || t.resolvingTarg 
      })
      if ( !tile ) return
      //console.log("Found tile: " + tile)
      
      const firstUnresolvedPh = phPane.phInsts.find(ph => !ph.aTile)
      tile.animate(firstUnresolvedPh)

      if ( tile.resolvingPhi && tile.group.position.equals(tile.resolvingPhi.phiRect.position) ) {
         phPane.renderPlaceHolderInsts(true)
         phPane.darsDone = await checkFinished()
      } else if ( tile.group.position.equals(tile.origin))  {
         phPane.renderPlaceHolderInsts(true)
         phPane.darsDone = await checkFinished()
      }
   }
   // Do not try window.onresize or addEventListener("resize"... as they dont work
   var sto = null
   paper.view.onResize = async () => {
      if ( sto ) {
         clearTimeout(sto)
      }
      sto = await setTimeout(this.initCanvasDarses, 250 )
   }

   const checkFinished = async () => {
      //let done = true
      for ( let phInst of phPane.phInsts ) {  
         if ( !phInst.aTile ) {
            return false
         }
      }
      return true
   }


   //document.getElementById("myCanvas").style.opacity =  1 
}
const initCanvasDarses = async (canvas, height ) => {
      console.log("INIT CANVAS DARSES, before")
      console.log("canvas width: " + canvas.width)
      console.log("canvas height: " + canvas.height)
       //const height = this.height
       canvas.style.backgroundColor = 'white'

       //utils.setPhiSide


      //this.canvas.width =   cons.canvasWidth; // window.innerWidth
      //this.canvas.height = cons.canvasHeight; // window.innerHeight
      console.log(cons)
                 //canvas.height = utils.getCanvasHeightDarses(darsHoroof); // window.innerHeight
      //this.canvas.width = width; // window.innerHeight
      //canvas.width = Math.min(1000,canvas.parentElement.clientWidth -10)  // window.innerHeight
      canvas.width = canvas.parentElement.clientWidth -10  // window.innerHeight
      console.log(document.getElementById('canvasContainer').clientHeight )
      console.log(canvas.parentElement.clientHeight)
      canvas.height = height -100 //   Math.max( 450, document.getElementById('canvasContainer').clientHeight - 75 )  //canvas.parentElement.clientHeight;
      console.log("Setup paper for canvas...")
      paper.setup(canvas);
      //canvas.height = 513 
      //console.log("canvas width: " + this.canvas.width )
      //console.log("canvas height: " + canvas.height)
      //await paper.setup(canvas);
      //window.paper = paper
      // scalaing like this wont fix toL issue
   /////     var toolPan = new paper.Tool()
   /////     toolPan.activate()

   /////   // On drag, scroll the View by the difference between mousedown
   /////   // and mouseup
   /////   toolPan.onMouseDrag = function (event) {
   /////      var delta = event.downPoint.subtract(event.point)
   /////      paper.view.scrollBy(0, delta.y)
   /////      //propagateDefaults()

   /////      console.log(event)
   /////   }


      // canvasContext.translate(window.innerWidth, 0);
      // canvasContext.scale(-1,1 );
      // canvasContext.save()

      //this line eliminates need to access everything through paper object
      // but as a sideeffect will impact global scope for example breaks browsersync
      //console.log(height)
      console.log("canvas width: " + canvas.width)
      console.log("canvas height: " + canvas.height)

     //if ( this.canvas.width < 800 ) {
     //  php.phiSide = 40
     //}
    }
const updateCanvasDars = (
   canvas,
   darsObj
   ) => {
  const { darsHarfKeys,darsHoroof, darsKalameh, recordModeToggle  } = darsObj
  const renderPane = getRenderArea() 
  const { phiSide, atiSide } = calcDims( renderPane,   darsHarfKeys.length, darsHoroof.length, 50)
  console.log("UPDATE CANVAS DARS ...")
  //window.paper = paper
   //renderPane.setPhiSide(darsHarfKeys.length) 
   //renderPane.setAtiSide(darsHoroof.length)
   window.renderPane = renderPane
   const phPane = utils.createPlaceHolderPane( renderPane, renderPane.rectTR, phiSide, darsHarfKeys, darsHarfKeys.length,  darsKalameh, recordModeToggle)
   window.phPane = phPane

   const phPaneBR = phPane.phpRect.bounds.bottomRight


   // Temp for here, to move inside class

   const avaiHeight = renderPane.rectBL.y - phPaneBR.y
   console.log("Avai Height " + avaiHeight )
   //const atiSide = 
   //


   let atPanes = [] 

   // iterate twice to make sure all ati are create on top phi
   //const atPaneCreated = {}
   //console.log(this.darsHoroof)

   // distance between php and first atp
   let topRight = phPane.phpRect.bounds.bottomRight.add(0,10) 

   for ( let harf of shuffle(darsHoroof) ) {

      //topRight = topRight.add(0,  idx*(atp.atpRow+10))
      let atPane = utils.createAlphatilePane(renderPane, topRight, atiSide, harf, phPane, darsHarfKeys) 
      topRight = atPane.atpRect.bounds.bottomRight
      //console.log(topRight)
      atPanes.push(atPane)

   }
   installTileAnimator(paper,phPane,atPanes, darsObj ) 
   //console.log("========================================== . >>>>>>>>>>>>>>>>>")
   //console.log(phPane)
   return phPane
}
const clearCanvas = async () => {
   if ( paper.project )
      paper.project.activeLayer.removeChildren();
   if ( paper.view )
      await paper.view.draw();
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const cleanUpdateCanvasDarses = async (canvas, darsObj ) => {
   console.log("CLEAN UPDATE CANVAS DARSES")
   //await this.$router.push(`/dars/${this.darsId}`)
   await clearCanvasDarses(canvas)
   await timeout(500)
   const phPane = await updateCanvasDars(canvas, darsObj )
   return phPane

}

const updateCanvasHoroof = async (learnedHoroof) => {
   clearCanvas()
   window.paper = paper
   let phPanes = []
   const renderPane = getRenderArea() 
  // renderPane.setPhiSide(8)   // maxim 8 harfs
  // renderPane.setAtiSide(1)
  const { phiSide } = calcDims( renderPane, 8 , 1, 80 )  // darsHarfKeys.length, darsHoroof.length)
  const atiSide = 60


   let topRight = renderPane.rectTR
   const phPane = utils.createPlaceHolderPane( renderPane, topRight, phiSide, learnedHoroof, 8, null)
   let idx=0;
   for ( let phInst of phPane.phInsts ) {
      console.log(learnedHoroof[idx]['harfLead'])
      let atInst = new ati( phiSide, phPane.getPhiTopRight(idx) , phPane.getPhiBottomLeft(idx), atiSide, phPane.phInsts, learnedHoroof[idx]['harfLead'] )
      atInst.group.position = phInst.phiRect.position
      phInst.aTile = atInst
      atInst.resolved = true
      atInst.group.firstChild.visible = false
      //this.ph.group.bounds = this.group.lastChild.bounds 
      phInst.phiRect.visible = false

      idx++
   }
   phPane.renderPlaceHolderInsts()
   phPanes.push(phPane)

   // iterate twice to make sure all ati are create on top phi


}
const clearCanvasDarses = async (canvas) => {
   // because there is another canvas for all-darses which gets
   // attached to paper, we need to this
   //
   await initCanvasDarses(canvas, window.innerHeight ) 
   //paper.setup(canvas)
   if ( paper.project )
      await paper.project.activeLayer.removeChildren();
   if ( paper.view )
      await paper.view.draw();
   //paper.view.clearRect(0, 0, paper.width,paper.height)
}
const updateCanvasAllDarses = async (reviewDarses, avaiHeight) => {
  const numRows = reviewDarses.length + 1
  const distRows = Math.min(90, avaiHeight / numRows)
  const widthPhp = Math.min(60, Math.floor(0.66 * distRows))
  clearCanvas()
  console.log(`Rendering ${reviewDarses.length} darses`)
  window.paper = paper
  let phPanes = []
  const renderPane = getRenderArea()

  let topRight = renderPane.rectTR
  for (let darsIdLoc of Array.from(Array(reviewDarses.length).keys())) {
    const dars = reviewDarses[darsIdLoc]
    // TODO : CHeck if only one bakhsh
    const bakhshs = dars.kalameh['kalamehHarfBakhshs'].reduce((tot, elem) => { tot.push(elem); tot.push("plus"); return tot }, [])
    bakhshs.pop()
    const keys = lodash.flattenDeep([dars.kalameh['kalamehHarfKeys'], "equal", bakhshs])
    //const keys = dars.kalameh["kalamehHarfKeys"]

    //const keys =   lodash.flatten([ dars.kalameh['kalamehHarfKeys'] ])
    const phPane = utils.createPlaceHolderPane(renderPane, topRight, widthPhp, keys,
      keys.length, 'all' + dars.kalameh.kalameh) // --> to fix the id crash, but not needed 
      //keys.length, dars.kalameh.kalameh)
    //console.log(ati)
     for (let [idx, phInst] of  phPane.phInsts.entries()) { // {[].entries() ) { 
      //console.log(phPane.getPhiTopRight(idx))
      //console.log(phPane.getPhiBottomLeft(idx))
      //let destKey = dars.kalameh['kalamehHarfKeys'][idx]
      let destKey = phInst.destKey
      let targetDestKey = destKey
      console.log(destKey)
      // harekat
      //if (destKey === '2' || destKey === 'a' || destKey === 'e' || destKey === 'o') {
      //  targetDestKey = `${prevDestKey}${destKey}`
      //  console.log(prevDestKey)
      //}
      // HERE
      if ((destKey === '2' || destKey === 'a' || destKey === 'o' || destKey === 'e') && idx > 0) {
        // do something here
        if (phPane.phInsts[idx - 1].destKey === '2') {
          targetDestKey = phPane.phInsts[idx - 2].destKey + phPane.phInsts[idx - 1].destKey + destKey
          console.log("EENJ2")
        } else {
          console.log("EENJ")
          targetDestKey = phPane.phInsts[idx - 1].destKey + destKey
        }
      }
      console.log(targetDestKey)
      let atInst = new ati(widthPhp, phPane.getPhiTopRight(idx), phPane.getPhiBottomLeft(idx), 60, phPane.phInsts, targetDestKey, targetDestKey)

      if (debugRendering) console.log(`new ati ${destKey} ${targetDestKey}`)
      atInst.group.position = phInst.phiRect.position
      phInst.aTile = atInst
      atInst.resolved = true
      atInst.freeze = true
      atInst.group.firstChild.visible = false
      //this.ph.group.bounds = this.group.lastChild.bounds 
      phInst.phiRect.visible = false

    } 
    topRight = topRight.add(0, distRows)
    phPane.renderPlaceHolderInsts(true)
    phPanes.push(phPane)
  }
  window.phPanes = phPanes

  // iterate twice to make sure all ati are create on top phi
  var sto = null
  paper.view.onResize = async () => {
    if (sto) {
      clearTimeout(sto)
    }
    sto = await setTimeout(updateCanvasAllDarses, 250)
  }

} 

const utils = {
  createPlaceHolderPane,
  createAlphatilePane, 
  updateCanvasDars,
  updateCanvasHoroof,
  updateCanvasAllDarses,
  initCanvasDarses,
  cleanUpdateCanvasDarses 
  //getCanvasHeightDarses
}

export { 
  atp, 
  ati, 
  phi,
  utils
};

