import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const state=  {
   kelas:null,
   kelasAudios: {},
   //kelasStudents: []
  }
const mutations= {
   kelas(state, kelas ) { 
      state.kelas = kelas
   },
   kelasAudios(state, kelasAudios) {
      state.kelasAudios = kelasAudios
   }
}
const actions= {
   async getKelas({commit, rootState, rootGetters},params) {
      const res = await axios.get(`${rootState.auth.backendHost}/api/kelases/${params.kelasId}`, rootGetters["auth/headerConfig"])
      const kelas = res.data
      for ( let stu of kelas.stuKelases ) {
         let student = stu.student
         if ( student.profileImage ) {
            let blob = new Blob([ new Buffer(student.profileImage.data, 'base64')], { type: 'image/jpg' });
            let url = window.URL.createObjectURL(blob)
            student.url = url
         }
         //console.log(student.kelasDarsIds)
         console.log(kelas)
         //console.log(student.kelasDarsIds.find(k => k.kelasId.toString() === kelas._id.toString()))
         //const kelasDarsIdObj = student.kelasDarsIds.find(k => k.kelasId.toString() === kelas._id.toString())
         // this piece of code is temporary to simplify transition into per-kelas darsId
        //if ( kelasDarsIdObj ) { 
        //   student.kelasDarsId = kelasDarsIdObj.darsId
        //} else {
        //   student.kelasDarsId = 1
        //}
      }
      commit("kelas", kelas)
   },
   async getKelasAudios({commit, rootState, rootGetters},params) {

      const res = await axios.get(`${rootState.auth.backendHost}/api/kelases/${params.kelasId}/dars/${params.darsId}`, rootGetters["auth/headerConfig"])
      const stuKelases = res.data.stuKelases
      console.log(stuKelases)
      let kelasAudios = {}
     for ( let stuKel of stuKelases ) {
        if ( !stuKel.audios[0] ) continue
        console.log(stuKel)
        let studentKelasAudio = stuKel.audios[0].audio.data
        //console.log(studentKelasAudio)
        if ( studentKelasAudio) {
           let blob = new Blob([ new Buffer(studentKelasAudio, 'base64')], { type: 'audio/mp3' });
           let url = window.URL.createObjectURL(blob)
           console.log(url)
           // this is wrong find id based on student
           kelasAudios[stuKel.student] = url
        }
     }
      commit("kelasAudios", kelasAudios)
   },
   // old before stuKelas
   async getKelasAudiosOld({commit, rootState, rootGetters},params) {
      const res = await axios.get(`${rootState.auth.backendHost}/api/kelases/${params.kelasId}/kelasAudios/${params.darsId}`, rootGetters["auth/headerConfig"])
      const kelasAudiosData = res.data.kelasAudios
      let kelasAudios = {}
      for ( let audioData of kelasAudiosData ) {
         const stuId = audioData.studentId
         let studentKelasAudio = audioData.audio.data
         console.log("Cheer")
         console.log(studentKelasAudio)
         if ( studentKelasAudio) {
            let blob = new Blob([ new Buffer(studentKelasAudio, 'base64')], { type: 'audio/mp3' });
            let url = window.URL.createObjectURL(blob)
            console.log(url)
            kelasAudios[stuId] = url
         }
      }
      commit("kelasAudios", kelasAudios)
   },
}

//const getAudioUrl = async (data) => {
//   //const blob = new Blob([ new Buffer(dars.kalamehAudio.data, 'base64')], { type: 'audio/m4a' });
//   const blob = new Blob([ new Buffer(data, 'base64')], { type: 'audio/m4a' });
//   //window.x = dars.kalamehAudio.data
//   const url = window.URL.createObjectURL(blob)
//   //window.audio = new Audio();
//   //window.audio.src = url;
//   //window.audio.play();
//   return url 
//  //this.audioDars[dars.kalameh] = url
//  //console.log("HERE  " + url)
//}

const getters = {
   kelasStudents(state) {
     //if (state.kelas) {
     //   return state.kelas.stuKelases.map(s => s.student )
     //} else
     //   return []

     if (state.kelas) {
        return state.kelas.stuKelases.reduce((tot, elem) => {
         console.log(elem)
           const student = elem.student
           console.log(student)
           student.darsId = elem.darsId
           tot.push(student)
           return tot
        }, [])
     } else
        return []
   },
   filteredKelasAudios(state) {
      console.log(state)
      return {}
      //return Object.keys(state.kelasAudios).filter(a => state.kelas.students.find(s=>s._id.toString() === a))
   },

}

export default{
   namespaced: true,
   state,
   actions,
   mutations,
   getters,
}

