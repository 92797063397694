<template>
   <v-container style="border-top:3px; max-width: 1200px;background-color:#E9DCC6"   class="my-2 px-1 bx-1">
      <!--
      <div>
      Debug Info: {{ this.window && this.window.debugObj ? 
      JSON.stringify(this.window.debugObj) : "NO" }}    </div>
      -->
      <v-row v-if=" noRender">
         <h1>
            این صفحه قابل نمایش نیست
         </h1>
         <h2>
            ۱. پهنای مرورگر را افزایش دهید یا

         </h2>
         <h2>
            ۲. دستگاه خود را در حالت افقی نگهدارید
         </h2>
      </v-row>
      <v-row v-else  no-gutters class="mt-3 mr-3" id="canvasContainer">

            
            <v-col sm="9" no-gutters class="mb-1  justify-center ">
                  <canvas v-show="currDars && currDars.darsType == 'KALAMEH'" display id="darsCanvas" class="mt-0"
                  :class="{'red-opa': recordMode}" :style="{ float: 'center'}">
                  </canvas>
                  <div v-if="recordMode" style="width: 100px; height:1px; margin: auto; text-align: center;">
                        <audio-recorder :show-upload-button="true"  :attemps="1" :show-download-button="false"
                                            :pause-recording="audioCallback" :after-recording="audioCallback">
                        </audio-recorder>
                     </div>
               <v-row v-if="currDars && currDars.darsType === 'ROOKHANI' && kelas && kelas.kelasName != 'ندارد'" style="height:60px" no-gutters align="top" class="mb-1">
                  <v-col  cols="3" lg="12"  class="mb-1 justify-center align-center">
                     
                     <audio style="display:none" id="recordingUrl" :src="recordingUrl" controls>
                     </audio>
                     <div :key="id" style="display:none" v-for="id in Object.keys(kelasAudios)">
                        <audio :id="'audio'+id"  controls :src="kelasAudios[id]">
                        </audio>
                     </div>
                     <div v-if="currDars && currDars.darsType === 'ROOKHANI'" style="height:50px">

                        <v-avatar>
                           <v-img @click="recordModeToggle" src="/images/mic.jpg">
                           </v-img>
                        </v-avatar>
                        <v-avatar :key="id" v-for="id in Object.keys(kelasAudios)">
                           <v-img width="80px" :id="id" height="80px" @click="playRecordedAudio(id)"
                                                        v-bind:src="kelasStudents.find(s=>s._id === id).url"> 
                           </v-img>
                        </v-avatar>
                        <!--
                           src="/images/mic.jpg">
                        -->
                     </div>
                    

                  </v-col>
               </v-row>
               <v-row id="subElemCont" class="col-12 pa-0">
                  <!--<img id="ear" style="display:none" src="images/ear.jpg" >  -->
                  <roo-khani v-if="currDars && currDars.darsType ==='ROOKHANI'">
                  </roo-khani>
                  <harf-start v-if="currDars && currDars.darsType ==='HARF_START'">
                  </harf-start>
                  <homework v-if="currDars && currDars.darsType ==='HOMEWORK'">
                  </homework>
                  <all-darses v-if="currDars && currDars.darsType ==='ALL_DARSES'">
                  </all-darses>
                  
                  <div id="allImagesDars">
                     <div v-for="(aG,aIdx) in darsHoroof" :key="aIdx">
                        <img v-for="alpha in aG['harfKeys']" :key="alpha" style="display:none" v-bind:id="alpha"
                        v-bind:src="imgAlph[alpha]">
                        <img id="ear" style="display:none" src="/images/ear.jpg">
                     </div>
                  </div>
                  <div id="allAudiosDars">
                     <audio :id="darsKalameh">
                        <!-- doesnt work with arrow keys:  <audio v-if="audioDars"  :id="darsKalameh"  >  -->
                        <source :src="audioDars" type="audio/mp3">
                        </audio>
                        <audio v-for="(aG,aIdx) in darsHoroof" :id="audioAlphId(aG)" :key="aIdx">
                           <source :src="audioAlph[aG.harfSounds[0]]" type="audio/mp3">
                        </audio>
                  </div>
               </v-row>
            </v-col>
            <v-col cols sm="3" class="mb-1">
               <left-panel :type="'dars'" :goToFirst="this.currDarsId == 1 ? null : this.goToFirstDars"
                                                    :goToPrev="this.currDarsId == 1 ? null : this.goToPrevDars"
                                                    :goToNext="this.currDarsId > this.kelasDarsId && !this.phPane.darsDone ? null : this.goToNextDars"
                                                    :goToLast="this.currDarsId > this.kelasDarsId && !this.phPane.darsDone ? null : this.goToLastDars">
               </left-panel>
            </v-col>
         </v-row>
   </v-container>
</template>

<script>
import { utils }  from '../class';
//import { mdiMenu  } from '@mdi/js';
//import { mdiHome, mdiChevronLeft, mdiChevronRight, mdiArrowRightBold, mdiArrowLeftBold } from '@mdi/js';
import { mdiViewHeadline, mdiChevronLeft, mdiChevronDoubleLeft, mdiChevronRight, mdiChevronDoubleRight  } from '@mdi/js';
import pn from 'persian-number';
import LeftPanel from './LeftPanel.vue';
import HarfStart from './HarfStart.vue';
import RooKhani from './RooKhani.vue';
import Homework from './Homework.vue';
import AllDarses from './AllDarses2.vue';
//import store from '../store';
import Vuex from 'vuex'
import axios from 'axios';


export default {
   name: 'Darses',
   components: {
      LeftPanel,
      HarfStart,
      RooKhani,
      Homework,
      AllDarses
   },
   data: function () {
      return {
         //mdiMenu ,
         height: 0,
         recordMode: false,
         recording: false,
         recordingUrl: null,
         noRender: false,
         //darsId: null,
         email: '',
         code: '',
         //selectedDarsId: '',
         darsDone: false, // 
         mdiChevronRight,
         mdiChevronLeft,
         mdiChevronDoubleRight,
         mdiChevronDoubleLeft,
         mdiViewHeadline,
         canvas: null,
         window,
         phPane: { darsDone : false},
         //goToDarses: [], 
    }
  },
  async beforeRouteUpdate(to,from,next) {
   if (this.currDars && this.currDars.darsType == "KALAMEH" ) { 
        this.$emit('darsId', this.currDarsId)
        const darsObj =  {
            darsHarfKeys: this.darsHarfKeys,
            darsHoroof: this.darsHoroof,
            darsKalameh: this.darsKalameh,
            recordModeToggle: this.recordModeToggle,
            darsDone : this.darsDone
        }
        this.phPane = await utils.cleanUpdateCanvasDarses(this.canvas, darsObj )
        //console.log("++++++++++++++++++++++++++++++++++")
        //console.log(this.phPane)
     } else {
      this.phPane = { darsDone: true } 

     }
     next()
  },
  watch: {
  },
  async created() {
      window.addEventListener("resize", this.resizeHandler);
  },
 async destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
 },
  async mounted() {

     this.window = window
     this.changeRTL()
     this.checkRes()

     await this.getStudentKelas({});
     if ( this.studentKelasId )
        await this.getKelas({kelasId: this.studentKelasId})

     await this.getHoroof();

     console.log ( this.$route.params.darsId ) 
     const urlDars = this.$route.params.darsId
     await this.getNumHarfLearned()
     //await this.getDars({currDarsId: urlDars } )
    if ( urlDars != 'latest' ) {
       if ( urlDars <= this.kelasDarsId + 1 ) { 
          await this.getDars({currDarsId: urlDars } )
       } else {
          await this.$router.push({name: 'darses',params: {darsId: 'latest'  }})
       }
    } else { 
     await this.getDars({currDarsId: urlDars } )
    }

     await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
        

      const canvas = document.getElementById('darsCanvas');

      this.canvas = canvas

      await utils.initCanvasDarses(canvas, window.innerHeight )
     if (this.currDars && this.currDars.darsType == 'KALAMEH') { 
        this.$emit('darsId', this.currDarsId)
        const darsObj =  {
            darsHarfKeys: this.darsHarfKeys,
            darsHoroof: this.darsHoroof,
            darsKalameh: this.darsKalameh,
            recordModeToggle: this.recordModeToggle,
            darsDone : this.darsDone
        }
        this.phPane = await utils.cleanUpdateCanvasDarses(canvas, darsObj )
        //console.log("++++++++++++++++++++++++++++++++++")
        //console.log(this.phPane)
     } else {
      this.phPane = { darsDone: true } 

     }
  },
  computed: { 

     ...Vuex.mapState("student",[ 
        "numHarfLearned",
        "kelasDarsId",
        "student",
        "currDarsId",
        "currDars",
        "audioDars",
        "studentKelasId",
        "stuKelId",
     ]),
     ...Vuex.mapState("harf",[ 
        "horoof",
        "audioAlph",
        "imgAlph",
        "harfFormsHier",
        "harfSoundsAll",
     ]),
     ...Vuex.mapState("kelas",[ 
        "kelas",
        "kelasAudios",
     ]),
     ...Vuex.mapGetters("student",[
        'darsKalameh',
        'darsHoroof',
        'darsHarfKeys',
        //'darsHarfForms',
        // ...
     ]),
     ...Vuex.mapGetters("kelas",[
        'kelasStudents',
        "filteredKelasAudios"
     ]),
     ...Vuex.mapState("auth",[
        'backendHost',
     ]),
     ...Vuex.mapGetters("auth",[
        'headerConfig',
     ]),
     compHeight() {
        switch (this.$vuetify.breakpoint.name) {
           case 'xs': return 100 
           case 'sm': return 150
           case 'md': return 170 
           case 'lg': return 200 
           case 'xl': return 200
        }
        return 200
     },
     harfStart () {
      if ( !this.horoof) return null
      if ( !this.currDars || !this.currDars.startOfHarves) return null
      return this.horoof.find( harf => harf.harfF === this.currDars.startOfHarves[0])
     },
    //[ 
    // { harfForm1: [ harfFor2, harfForm2]}  
    //]
   //  harfStartHarfForms () {
   //  if (!this.harfStart) return []
   //  const harfForms = this.harfStart.harfForms.reduce((tot,harfForm,idx) => {
   //     const harfGroup =  this.harfStart.harfGroups[idx]
   //     if ( harfGroup  === 'self' ) {
   //        const o = {}
   //        o[harfForm] = []
   //        tot.push(o)
   //     } else {
   //        tot[tot.length-1][harfGroup].push(harfForm)
   //     } 
   //     return tot
   //  },[])
   //  return harfForms
     //return this.harfStart.harfForms.filter ( (harfForm,idx) => {
     //   if ( this.harfStart.harfGroups[idx] === 'self' 
     //    // && (
     //    //    harfForm.includes("koochik") ||
     //    //    harfForm.includes("chasban") ||
     //    //    harfForm.includes("bikola"))
     //   ) return true
     //   return false
     //}) 

   //  },


  },
  //
  methods: {
     
     ...Vuex.mapActions("student",[
        "getStudentKelas",
        "getDars",
        "getNumHarfLearned",
        //"incrStuDarsId",
     ]),
     ...Vuex.mapActions("kelas",[
        "getKelas",
        "getKelasAudios",
        "getReviewDarsesHarf"
     ]),

     ...Vuex.mapActions("harf",[
        "getHoroof",
     ]),
     async resizeHandler() {
        console.log("Window got resized")
        if ( this.canvas ) {
      await utils.initCanvasDarses(this.canvas )
        }
      const darsObj =  {
            darsHarfKeys: this.darsHarfKeys,
            darsHoroof: this.darsHoroof,
            darsKalameh: this.darsKalameh,
            recordModeToggle: this.recordModeToggle,
            darsDone : this.darsDone
        }
        await utils.cleanUpdateCanvasDarses(this.canvas, darsObj )
        //this.$forceUpdate()

     },
     changeToGif(x,audioId) {
        document.getElementById(audioId).play()
        const oldSrc = x.target.src
        const newSrc =  oldSrc.replace(".png", ".gif") 
        console.log(newSrc)
        x.target.src = `/gifs/${x.target.id}.gif`
      },
     playRecordedAudio(id) {
        document.getElementById('audio'+id).play()
     },
     async blobToBase64(blob) {
        return new Promise((resolve) => {
           const reader = new FileReader();
           reader.onloadend = () => resolve(reader.result.split(',')[1]);
           reader.readAsDataURL(blob);
        });
     },
     async audioCallback(data) {
        console.debug(data);

        //await this.cleanUpdateCanvas()
        //return
        if (data && data.url) {

           console.log("Here")
           //console.log(data.blob)
           const audioData = await this.blobToBase64(data.blob)
            //let newblob = new Blob([ new Buffer(audioData, 'base64')], { type: 'audio/mp3' });
           //const newaudioData = await this.blobToBase64(newblob)
           //console.log(newblob)
           //console.log(audioData)
           //console.log(newaudioData)
           const payload = { 
              darsId: this.currDarsId ,
              audio: audioData 
           }
           // old implementation
           //await axios.post(`${this.backendHost}/api/kelases/${this.kelas._id}/kelasAudios`, payload   , this.headerConfig )
           //  await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
           // new implementations
           await axios.put(`${this.backendHost}/api/students/${this.student._id}/studentKelas/${this.stuKelId}`, payload   , this.headerConfig )
        await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})

           //    let payload = new FormData() 
           //    payload.append('studentId', this.student._id)
           //    payload.append('darsId', this.studentDarsId)
           //    payload.append('audioData', data.blob)
           //      await axios.post(`${this.backendHost}/api/kelases/${this.kelas._id}/kelasAudios`, payload   , {...this.headerConfig, 'Content-Type' : 'multipart/form-data'  } )

           //for ( let studentId of updatedKelas.kelasAudios[this.studentDarsId] ) {
           //   let blob = new Blob([ new updatedKelas.kelasAudios[this.studentDarsId][studentId], 'base64'], { type: 'autio/mp4' });
           //   window.URL.createObjectURL(blob)
           //}
           //  let blob = new Blob([ new Buffer(data.blob), 'base64'], { type: 'autio/mp4' }) 
           let url =  window.URL.createObjectURL(data.blob)
           this.recordingUrl = url; // window.URL.createObjectURL(data.blob);

           //   // var link = document.createElement("a");
           //   // link.href = this.url;
           //   // link.innerHTML = "download here";
           //   // link.download = `voice_${this.index}.mp3`;
           //   // // link.style = "display: none";
           //   // document.body.appendChild(link);
           //   // link.click();
           //   // console.log("dine");
           //   // window.URL.revokeObjectURL(this.url);
        }
        this.recordMode = false
     },
     toggle_record() {
        this.recording = !this.recording;
     },
     async recordModeToggle() {
        this.recordMode = !this.recordMode
     },
     async goToFirstDars() {
        await this.getDars({currDarsId: 1 } )
        await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
        await this.$router.push({name: 'darses',params: {darsId:1  }})
     },
     async goToNextDars() {
       //if ( this.currDarsId > this.studentDarsId ) { 
       //   this.incrStuDarsId(this.currDarsId)
       //}
        await this.getDars({currDarsId: this.currDarsId + 1 } )

        await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
        await this.$router.push({name: 'darses',params: {darsId:this.currDarsId  }})
     },
     async goToLastDars() {
        await this.getDars({currDarsId: this.kelasDarsId + 1 } )
        await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
        await this.$router.push({name: 'darses',params: {darsId:this.currDarsId }})
     },
     async goToPrevDars() {
        console.log(`-----> ` + this.currDars.darsType  ) 
        await this.getDars({currDarsId: this.currDarsId -1 } )
        await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
        await this.$router.push({name: 'darses',params: {darsId:this.currDarsId }})
     },  
     async getLatestDars() {
        this.getDars({currDarsId: "latest"})
        //this.addDarsAudio();
     },

     checkRes() {
       if (  window.innerWidth < window.innerHeight ) {    
          this.noRender = true
       }
        //this.height = Math.max(600, document.getElementById("navColumn").offsetHeight )
        console.log("Height: " + window.innerHeight + ",Width: " + window.innerWidth)
        //const height = window.innerHeight 
        console.log(`Mounted with height ${this.height}`)
        //return this.height
     },
    changeRTL () {
      this.$vuetify.rtl = true
    },
        
    
   //totalDarses() {
   //  return cons.darses.length
   //},
    pn(num) {
      //return pn.convert(num)
      return pn.convertEnToPe(num)
    },
    //async goToAllDarses() {
    //  this.$router.push({name: 'all_darses' , query: { page:1 }  })
    //},
   //async goToNextDars() {
   //  this.darsId++;
   //  if ( this.darsId <= this.student.darsId ) { 
   //    // nothing
   //  } else { 
   //    this.student.darsId++
   //    this.student = Object.assign({},this.student)
   //    await axios.put(`${this.backendHost}/api/students/${this.student._id}`, this.student , this.headerConfig)
   //    //await axios.put(`${this.backendHost}/students/${this.code}?email=${this.email}`, { student: this.student.student, darsId: this.darsId  })
   //   //this.student = (await axios.get(`${this.backendHost}/students/${this.code}?email=${this.email}`)).data
   //   //this.darsDone = false
   //  }
   //  //await this.$router.push({name: 'main',params: {darsId:this.darsId}})
   //  //await this.addDarsAudio()
   //},
   //async goToFirstDars() {
   //  this.darsId = 1
   //},
   //async goToLastDars() {
   //  this.darsId = this.student.darsId + 1
   //},
   //async goToPrevDars() {
   //  this.darsId--;
   //  //this.$router.push({name: 'main',params: {darsId:this.darsId}})
   //},
     // TODO: refactor and use below method 6/2/22
    async goToDars(n) { 
      this.currDarsId = n
      //await this.addDarsAudio()
    },
    
    //audioAlph(aG) {
    //  return '/audios/' + aG['harfSound'] + '.m4a'
    //},
    audioAlphId(aG) {
      return aG['harfSounds'][0]
    },

    
    
  }
}
</script>

<style>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
a {
    text-decoration: none;
}

.ar {
   position: relative;
   top: 400px;
   left: 200px;
   background-color: blue

}

.ar-records {
  display: none !important;
}

.ar-player {
  display: none !important;
}

.red-opa {
  #opacity: 0.1;
  #z-index: -10 ;
  visibility: hidden;
   
}
img {
  border: 5px solid #555;
  margin: 3px;
}
</style>
