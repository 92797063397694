<template>
   <v-container>
      <v-container class="pa-0" v-if="!noRender">
         <v-row id="row-of-horoofcards" class="text-center">
                  <!-- <v-spacer> </v-spacer> -->
                  <v-col class="py-1 px-2" cols="12"  sm="12" >
                     <v-card class="d-none d-sm-block mx-auto">
            
                        <!--
                        <v-img class="mx-auto" height="100px" width="100px" :src='student.imgProfile'>
                        </v-img>
                        -->
            
                        <v-card-title class="justify-center">
                           <h3>
                              {{
                                 this.rtl ? 
                               this.student.fnaam || '' : 
                               this.student.fname || ''  
                              }}
                           </h3>

                        </v-card-title>
                        <!--
                           <h3>
                              {{ this.darsInfo }}
                           </h3>
                        -->
            
                        <v-divider class="mx-3"></v-divider>
                        <v-card-text style="display:block" :class="this.rtl ? 'text-right' : 'text-left'">
                           <!--
                                                      <h3>
                                                         درس :  
                                                         <router-link :to=" { name: 'darses',  params: {darsId: `${this.darsId}` }}" > 
                                                         {{ pn(this.darsId) }}
                                                         </router-link>
                                                      </h3>
                                                      -->
                           <h3 v-if="studentKelas != 'ندارد'">
                            {{ 
                                    this.rtl ?
                              "کلاس :"
                                    :
                                    "classroom :"
                                    }}

            
                              <router-link :to="{path: '/ui/kelas'}">
                                 {{studentKelas}}

                              </router-link>
                           </h3>
            
                           <h3>
                              <!-- دروس تمام شده : -->
                              
                              {{
                                 this.rtl ? 
                              "دروس پایان یافته :" :
                              "Finished Lessons:"
                              }}
                              <router-link :to="{name: 'all_darses', 'params': { 'page': `${this.numHarfLearned - 1}` }}">
                                 {{ 
                                    this.rtl ?
                                    pn(this.kelasDarsId) 
                                    :
                                    this.kelasDarsId
                                    }}
                              </router-link>
                              <!--    را به پایان رسانده است     -->
                           </h3>
                           <h3>
                              {{ 
                                 this.rtl ?
                              "نشانه های اموخته:"

                              : 
                              "Learned Sounds:"
                              }}
                              <router-link :to="{ path: '/ui/alefba' }">
                                 {{ this.rtl ? 
                                  this.pn(this.numHarfLearned)  :
                                  this.numHarfLearned
                                  }}
                              </router-link>
                              <!-- از الفبا را آموخته است   -->
                           </h3>
                        </v-card-text>
                     </v-card>
                  </v-col> 
                  <v-col class="py-1 px-2" cols="12"  sm="12" >
                     <div>
                        <v-row dense>
                        <!--
                           <v-col cols="4">
                              <v-btn style="width:100%"
                                 :to=" { name: 'darses',  params: {darsId: `${this.currDarsId || this.kelasDarsId}` }}"
                                 :class="{'disable-btn': type === 'dars'}" class="mt-2" color="blue lighten-1">
                                 <v-checkbox>
                                 </v-checkbox>
                                 <v-icon>
                                    {{ mdiViewQuilt }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
                           <v-col cols="4">
                              <v-btn style="width:100%" :to="{name: 'all_darses', 'params': { 'page': this.allDarsesPage  }}"
                                 :class="{'disable-btn': type === 'review'}" class="mt-2" color="blue lighten-1">
                                 <v-checkbox>
                                 </v-checkbox>
                                 <v-icon>
                                    {{ mdiViewSequential }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
                           <v-col cols="4">
                              <v-btn style="width:100%" 
                                 :to="{ name: 'darses', params: { 'darsId': this.darsRookhani }}" 
                                 :class="{'disable-btn': type === 'alefba'}"
                                 class="mt-2" color="blue lighten-1">
                                 <v-icon>
                                    {{ mdiAccountVoice }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
                        -->
                           <!--
                           <v-col cols="4">
                              <v-btn style="width:100%" :to="{ path: '/ui/alefba' }" :class="{'disable-btn': type === 'alefba'}"
                                 class="mt-2" color="blue lighten-1">
                                 <v-icon>
                                    {{ mdiViewComfy }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
                           -->
                        </v-row>
                        <v-row dense>
            
            
                           <v-col cols="6">
                              <v-btn style="width:100%" @click="goToPrevMeth" :class="{'disable-btn': !goToPrev}" class="mt-2"
                                 color="blue lighten-1">
                                 <!-- درس قبل  -->
                                 <v-icon large>
                                    {{ this.rtl ?  mdiChevronRight : mdiChevronLeft }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
                           <v-col cols="6">
                              <v-btn style="width:100%" @click="goToNextMeth" :class="{'disable-btn': !goToNext }" class="mt-2"
                                 color="blue lighten-1">
                                 <!-- درس بعد -->
                                 <v-icon large>
                                    {{ this.rtl ?  mdiChevronLeft : mdiChevronRight }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
            
                        </v-row>
            
            
                        <v-row dense>
                           <v-col cols="6">
                              <v-btn style="width:100%" @click="goToFirstMeth" :class="{'disable-btn': !goToFirst}" class="mt-2"
                                 color="blue lighten-1">
                                 <!-- درس قبل  -->
                                 <v-icon large>
                                    {{ this.rtl ? mdiChevronDoubleRight : mdiChevronDoubleLeft }}
                                 </v-icon>
                              </v-btn>
                           </v-col>
            
            
                           <v-col cols="6">
                              <v-btn style="width:100%" @click="goToLastMeth" :class="{'disable-btn': !goToLast }" class="mt-2"
                              color=" blue lighten-1">
                              <v-icon large>
                                 {{ this.rtl ? mdiChevronDoubleLeft : mdiChevronDoubleRight }}
                              </v-icon>
                           </v-btn>
                        </v-col>
                     </v-row>
                  </div>
                  <div>
                     <v-select v-if="type==='dars'" @change="goToLetterStart" class="mt-2" :items="goToDarses" item-text="text"
                        item-value="value" item-color="black" background-color="blue lighten-1" v-model="selectedDarsId" solo>
               
                        <template v-slot:item="{item , attrs, on}">
                           <v-list-item class="px-1" v-on="on" v-bind="attrs">
                              <v-container class="" style="background-color:#90CAF9">
                                 <h2>
                                    {{ item.text }}
                                 </h2>
                              </v-container>
                           </v-list-item>
                        </template>
                        <template v-slot:label>
                        </template>
                        <template v-slot:selection="{item}">
                           <h2 style="color:black">
                              {{ item.text }}
                           </h2>
                        </template>
               
                     </v-select>
                     <v-select v-if="type==='review'" @change="goToReviewLetterStart" class="mt-2" :items="goToHarfReviewDarses"
                        item-text="text" item-value="value" item-color="black" background-color="blue lighten-1" v-model="selectedHarf"
                        solo>
               
                        <template v-slot:item="{item , attrs, on}">
                           <v-list-item class="px-1" v-on="on" v-bind="attrs">
                              <v-container class="" style="background-color:#90CAF9">
                                 <h2>
                                    {{ item.text }}
                                 </h2>
                              </v-container>
                           </v-list-item>
                        </template>
                        <template v-slot:label>
                        </template>
                        <template v-slot:selection="{item}">
                           <h2 style="color:black">
                              {{ item.text }}
                           </h2>
                        </template>
               
                     </v-select>
                     <v-select v-if="type==='alefba'" disabled class="mt-2" background-color="#42A5F5" style="opacity:0.2" solo>
               
                     </v-select>
               
               </div>
               </v-col>
         </v-row>
      </v-container>
   </v-container>
</template>

<script>
//import { mdiHome, mdiChevronLeft, mdiChevronRight, mdiArrowRightBold, mdiArrowLeftBold } from '@mdi/js';
import { mdiViewHeadline, mdiChevronLeft, mdiChevronDoubleLeft, mdiChevronRight, mdiChevronDoubleRight  } from '@mdi/js';
import pn from 'persian-number';
import Vuex from 'vuex'
import Vue from "vue";
import { mdiViewQuilt   } from '@mdi/js';
import { mdiViewSequential } from '@mdi/js';
//import { mdiViewComfy } from '@mdi/js';
import { mdiAccountVoice } from '@mdi/js';

export default {
  name: 'LeftPanel',
  props: [ 
    // "pageId",
     "type", 
     "goToFirst",
     "goToNext",
     "goToPrev",
     "goToLast",
  ],
  data: function () {
    return {
       noRender: false,
       mdiViewQuilt,    
       mdiAccountVoice,
       mdiViewSequential,
       //darsId: null,
       //email: '',
       //code: '',
       //dars: null,
       //horoof: [],
       //selectedDarsId: null, //  this.$route.params.darsId,
       //selectedHarf: null, 
       //student: '',
      //mdiArrowRightBold,
      //mdiArrowLeftBold,
      audioDars: '' ,
      audioAlph: {},
      //imgProfile: null,
      mdiChevronRight,
      mdiChevronLeft,
      mdiChevronDoubleRight,
      mdiChevronDoubleLeft,
      mdiViewHeadline,
      //canvas: null,
      //goToDarses: [], 
      backendHost: process.env.NODE_ENV === 'development' ? 'http://localhost:3085'  : ''
    }
  },
  watch: {
     //async selectedHarf(newVal) {
     //   if ( newVal && !isNaN(newVal)) { 
     //      await this.getReviewDarsesHarf({pageId: newVal -1  }) // this.$route.query.page})
     //      await this.$router.push({name: 'all_darses',params: {page:newVal -1 }})
     //   }
     //},

    //async currReviewDarsId(newVal) {
    //   this.selectedHarf = this.goToHarfReviewDarses.find( a => a.value === newVal )
    //},
   // async currDarsId(newVal) {
   //    let temp
   //    for ( let dars of this.goToDarses ) { 
   //       if ( dars.value > newVal  ) {
   //          break
   //       }
   //       temp = dars 
   //    }
   //    this.selectedDarsId = temp
   // },
    //async goToDarses(newVal) {
    //   for ( let dars of newVal ) { 
    //      if ( dars.value >= this.currDarsId ) {
    //         this.selectedDarsId = dars
    //         break
    //      }
    //   }
    //},
    async goToHarfReviewDarses(newVal) {
       if ( newVal)
          return this.selectedHarf = newVal.find(a => a.value === this.$route.params.page) 
       else 
          return [] 
    },

  },
   async mounted() {

      console.log("Left Panel: ");
      //this.selectedHarf = this.goToHarfReviewDarses.find( a => a.value === this.pageId + 1)
      console.log(this.currDarsId)
      //let temp
      
      Vue.nextTick(() => {
        // if ( this.goToDarese.length ) {
      //  for ( let dars of this.goToDarses ) { 
      //    if ( dars.value > this.currDarsId  ) {
      //       break
      //    }
      //    temp = dars 
      //   }
      // this.selectedDarsId = temp
        // } else if ( this.goToHarfReviewDarses.length ) {
        //this.selectedHarf = this.goToHarfReviewDarses.find( a => a.value === this.currReviewPageId )
        // }
      })
    
  },
  computed: { 

    ...Vuex.mapState([
      "rtl",
    ]),
     ...Vuex.mapState("student",[ 
        "student",
        "currDarsId",
        "currDars",
        "kelasDarsId",
        "studentKelas",
        "studentEngKelas",
        "numHarfLearned",
        "currReviewPageId"
     ]),
     ...Vuex.mapGetters("harf",[ 
       // "goToHarfReviewDarses",
     ]),
     ...Vuex.mapGetters("student",[ 
        "currDarsNumHarf",
        "goToDarses",
        "studentKelas"
     ]),
     darsInfo() {
    //    if ( !this.currDars ) return ''
    //    return "ksjskj"
       const val =   this.rtl ? 
          "درس شماره " + this.pn(this.currDars.darsId)  : 
          this.currDars.darsId  
        return val
     },
     allDarsesPage() {
        if (this.currDarsNumHarf > 1 ) {
           if ( this.currDars.darsType == "HARF_START") {
               return this.currDarsNumHarf
           } else { 
               return this.currDarsNumHarf -1; 
           }
        } else { 
           return 1
         }
     },
    darsRookhani() {
       
    //  if (this.currDarsNumHarf > 1 ) {
    //     if ( this.currDars.darsType == "HARF_START") {
    //        return this.currDarsNumHarf
    //     } else { 
    //        return this.currDarsNumHarf -1; 
    //     }
    //  } else { 
    //     return 1
    //  }
       return 1
    },
    //selectedHarf() {
    //   return this.goToHarfReviewDarses.find( a => a.value === this.currReviewPageId + 1 )
    //},
     selectedDarsId() {
        let temp
        for ( let dars of this.goToDarses ) { 
           if ( dars.value > this.currDarsId  ) {
              break
           }
           temp = dars 
        }
        return temp
     }
  },
  //
  methods: {
   ...Vuex.mapActions("student",[
        "setDarsId",
        "getDars",
        "getKelasAudios",
        "getReviewDarsesHarf"
     ]),
     async goToLetterStart(newVal) {
       if ( newVal && !isNaN(newVal) && newVal.value != this.currDarsId ) { 
          await this.getDars({currDarsId: newVal } )
          await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
          await this.$router.push({name: 'darses',params: {darsId:this.currDarsId }})
       }
     },
     async goToLetterRookhani(newVal) {
       if ( newVal && !isNaN(newVal) && newVal.value != this.currDarsId ) { 
          await this.getDars({currDarsId: newVal } )
          await this.getKelasAudios({kelasId: this.studentKelasId, darsId: this.currDarsId})
          await this.$router.push({name: 'darses',params: {darsId:this.currDarsId }})
       }
     },
     async goToReviewLetterStart(newVal) {
        if ( newVal && !isNaN(newVal)) { 
           await this.getReviewDarsesHarf({pageId: newVal -1  }) // this.$route.query.page})
           await this.$router.push({name: 'all_darses',params: {page:newVal -1 }})
        }
     },
     goToFirstMeth() {
        //this.selectedDarsId= ''
        if ( this.goToFirst ) {
           this.goToFirst()
        } else {
           return
        }
     },
     goToLastMeth() {
        //this.selectedDarsId= ''
        if ( this.goToLast ) {
           this.goToLast()
        } else {
           return
        }
     },

     goToNextMeth() {
        //this.selectedDarsId= this.currDarsId 
        if ( this.goToNext ) {
           this.goToNext()
        } else {
           return
        }
     },
     goToPrevMeth() {
        //this.selectedDarsId= this.currDarsId 
        //this.selectedDarsId= ''
        if ( this.goToPrev ) {
           this.goToPrev()
        } else {
           return
        }
     },
     kelasLinkIdx(idx) {
        if (idx) {
           return { path: '/ui/kelas', query: {kelasIndex: idx  }}
        } else {
           return { path: '/ui/kelas' }
        }

   },
//   changeRTL () {
//     this.$vuetify.rtl = true
//   },
     pn(num) {
        //return pn.convert(num)
        return pn.convertEnToPe(num)
     },
//   //async goToAllDarses() {
//   //  this.$router.push({name: 'all_darses' , query: { page:1 }  })
//   //},
   
//   async goToDars(n) { 
//     this.darsId = n
//     //await this.addKalamehAudio()
//   },
//   async cleanUpdateCanvas() {
//     //await this.$router.push(`/dars/${this.darsId}`)
//     await this.clearCanvas()
//     await setTimeout ( async () => { 
//       await this.updateCanvas()
//     }, 1000 )
//
//   },
//   //audioAlph(aG) {
//   //  return '/audios/' + aG['harfSound'] + '.m4a'
//   //},
//   audioAlphId(aG) {
//     return aG['harfSounds'][0]
//   },
//

  }
}
</script>

<style scoped>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
a {
    text-decoration: none;
}
.v-list-item--link::before { background-color: "#64B5F6"; }
.theme--light.v-list-item:hover::before { opacity: 0.33; }



</style>
