<template>

<v-container class="fill-height">
    <v-row class="mx-3" align="center" justify="center" >
    <v-col cols="12" xs="12" sm="8" md="6">


    <!--    REGISTERATION MODAL START -->
    <v-dialog v-model="showRegisterDialog" max-width="600px">
      <template >
        <!--
        <v-btn color="blue lighten-2" dark class="mb-2" v-bind="attrs" v-on="on">
          Add Student
        </v-btn>
      -->
      </template>
      <v-card>
        <v-card-title class="blue lighten-1" >
            <span class=" flex text-center headline">
          {{ this.rtl ?
              "ثبت نام در شکر"
               :
               "SHEKKAR REGISTRATION"
          }}
              </span>
        </v-card-title>
  
        <v-card-text>
          <v-container>
              <v-form v-model="registValid" fast-fail @submit.prevent>
              <v-row>
                <v-col class="d-flex justify-center" cols="12">
                  <v-image-input v-model="imageData" overlay-padding="25px" :image-width="128" :image-height="128"  :image-quality="0.85"  clearable image-format="jpeg"
                    @file-info="onFileInfo" />

                </v-col>
              </v-row>
              <v-row >
                
                <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                    <v-text-field 
                      v-model="editedItem.email" 
                      :label="emailLabel"
                      :rules= "emailRules"
                      type="email"
                    >
                    </v-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                  <v-text-field 
                     v-model="editedItem.code" 
                     :label="passwdLabel"
                     :rules= "passwordRules"
                     >
                    </v-text-field>
                </v-col>
                <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                        <v-text-field :rules="passwordRules" required v-model="editedItem.fname" label="First Name">
                        </v-text-field>
                 </v-col>
                <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                        <v-text-field :rules="passwordRules" required v-model="editedItem.lname" label="Last Name">
                        </v-text-field>
                 </v-col>
                 <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                    <v-text-field :rules="passwordRules" v-model="editedItem.fnaam" label="نام">
                    </v-text-field>
                 </v-col>
                 <v-col class="py-1" cols="12" sm="6" md="6" xs="6">
                    <v-text-field :rules="passwordRules" v-model="editedItem.lnaam" label="نام خانوادگی " >
                    </v-text-field>
                 </v-col>

                </v-row>
                </v-form>
            </v-container>
        </v-card-text>
  
        <v-card-actions>
          <v-btn class="flex" color="grey" @click="close">
          {{ this.rtl ?
            "بازگشت"
            :
            "CANCEL"
          }}
          </v-btn>
          <v-btn class="flex" :disabled="!registValid" color="#64B5F6" @click="submitReg">
          {{ this.rtl ?
          "ثبت نام کن"
            :
            "REGISTER"
          }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    REGISTERATION MODAL END  -->

    <!-- FORGOTPASS CARD START --> 
    <v-card v-if="showPassRstCard" class="elevation-12">
      <v-card-title style="display:block" class="blue lighten-1 text-h4  white--text mt-8">
          {{ this.rtl ?
            "بازیافت رمز ورود"
            :
            "PASSWORD RESET"
          }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-form v-model="isFormValid">
          <v-text-field 
              :label="emailLabel" 
              name="login" 
              prepend-icon="mdi-email" 
              type="text"
              :rules= "emailRules"
              v-model="email">
          </v-text-field>
          
        </v-form>
      </v-card-text>
      <v-card-actions>
        <!--
        "I have forgot my password"
        "رمز ورود را فراموش کرده‌ام"
        -->
        <v-spacer></v-spacer>
        <v-btn class="flex" color="grey" @click="showLoginCardMeth">
          {{ this.rtl ?
            "بازگشت"
            :
            "CANCEL"
          }}
          </v-btn>
        <v-btn 
              :disabled="!isFormValid" 
              medium 
              color="#64B5F6"
              @click="sendPasswordResetLink()" 
              >
                {{
                  this.rtl ?
                  "دریافت لینک بازیافت رمز ورود"
                  :
                  "Send me password reset link"
                }}
          </v-btn>
        </v-card-actions>
        </v-card>
    <!-- FORGOTPASS CARD END --> 



    <!-- LOGIN CARD START --> 
    <v-card v-if="showLoginCard" class="elevation-12">
      <v-card-title style="display:block" class="blue lighten-1 text-h4  white--text mt-8">
          {{ this.rtl ?
            "به شکر خوش آمدید"
            :
            "Welcome to Shekkar"
          }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-form v-model="isFormValid">
          <v-text-field 
              :label="emailLabel" 
              name="login" 
              prepend-icon="mdi-email" 
              type="text"
              :rules= "emailRules"
              v-model="email">
          </v-text-field>
    
          <v-text-field 
              id="password" 
              :label="passwdLabel" 
              name="password" 
              v-model="password" 
              :rules="passwordRules"
              prepend-icon="mdi-lock"
            type="password">
          </v-text-field>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <!--
        "I have forgot my password"
        "رمز ورود را فراموش کرده‌ام"
        -->
        
        <v-spacer></v-spacer>
          <v-btn 
              :disabled="!isFormValid" 
              medium 
              @click="goToSchool(email, password)" 
              color="#64B5F6">
            {{ this.rtl ?
              "برو به مدرسه"
              :
              "Go To School"
            }}
          </v-btn>
      </v-card-actions>
      <!--
      <v-card-title v-if="!showErrCard">
      </v-card-title>
      -->
      <v-card-actions v-if="!showErrCard && !showRegInfCard">
        <!--
          {{ this.rtl ?
        "تازه اینجا آمده اید؟ ثبت نام کنید"
        : 
          "New to Shekkar? Register here"
        }}-->
        <v-btn  
              medium 
              @click="showPassResetMeth()" 
              color="grey">
                {{
                  this.rtl ?
                  "رمز ورود را فراموش کرده ام"
                  :
                  "Forgotten Password"
                }}
          </v-btn>
          <v-btn medium @click="openRegDialog" color="#64B5F6">
            {{ this.rtl ?
             "در شکر ثبت نام کنید"
            :
            "REGISTER IN SHEKKAR"
            }}
            </v-btn>
      </v-card-actions>
      </v-card>
    <!-- LOGIN CARD END --> 
    <!-- REGISTRATION-ERROR CARD START --> 
      <v-card v-if="showErrCard">
      <v-card-title  text class="red--text lighten-2"> 
          <div>
          {{ this.rtl ?  
          this.cardErrMsgFarsi : 
          this.cardErrMsgEng 
          }}
          </div>
          <div v-if="serverErrMsg === 'EMAIL-NOT-VERIFIED'">
            {{
              this.rtl ?
              "به ایمیل ثبت نام مراجعه نموده و لینک داخل آن را دنبال کنید"
              :
              "Goto to registration email and click the included link"
            }}
        </div>
      </v-card-title>
      <v-card-actions  v-if="serverErrMsg==='EMAIL-NOT-VERIFIED'">
        <v-btn class="flex"  @click="showLoginCardMeth" color="grey">
          {{ 
          this.rtl ? 
            "بازگشت"
            : 
            "RETRUN TO LOGIN"
          }}
          </v-btn>
        <v-btn class="flex" medium @click="resendEmail" color="#64B5F6">
          {{  
          this.rtl ?
          "ایمیل را دوباره بفرست"
          : 
          "RESENT CONFIRMATION EMAIL"
          }}
        </v-btn>
      </v-card-actions>
      <v-card-actions  v-if="serverErrMsg==='AUTH-FAIL' || serverErrMsg==='REGISTRATION-FAIL'">
        <v-btn class="flex"  @click="showLoginCardMeth" color="grey">
        {{ 
          this.rtl ? 
            "بازگشت"
            : 
            "RETRUN TO LOGIN"
          }}
          </v-btn>
      </v-card-actions>
    </v-card>
    <!-- REGISTRATION-ERROR CARD END --> 

    <!-- REGISTRATION-INFO CARD START --> 
    <v-card v-if="showRegInfCard">
      <v-card-title  text class="lighten-2"> 
        <div>
        {{ this.rtl ?  this.infoMsgFarsi : this.infoMsgEng }}
        </div>
      </v-card-title>
      <v-card-actions >
        <v-btn class="flex"  @click="showLoginCardMeth" color="grey">
          {{ 
          this.rtl ? 
            "بازگشت"
            : 
            "RETRUN TO LOGIN"
          }}
          </v-btn>
      </v-card-actions>
    </v-card>
    <!-- REGISTRATION-INFO CARD END --> 

    <!-- FORGOT-PASS CARD START  
    <v-card v-if="showInfoPass">

      <v-card-title  text class="lighten-2"> 
        <div>
          {{ this.infoPassMsg }}
        </div>
      </v-card-title>
      <v-card-actions >
        <v-btn class="flex"  @click="showRegInfCard=false" color="grey">
          {{ 
          this.rtl ? 
            "بازگشت"
            : 
            "RETRUN TO LOGIN"
          }}
          </v-btn>
      </v-card-actions>
    </v-card>-->
    <!-- FORGOT-PASS CARD END --> 
    

      </v-col>
            </v-row>
            </v-container>

</template>

<script>

import axios from 'axios'
import Vuex from 'vuex'

export default {
  name: 'Login',
  data: function () {
    return {
      showRegisterDialog: false,
      // Mutex set of variables 
      showErrCard: false,
      showRegInfCard: false,
      showLoginCard: true,
      showPassRstCard: false,
       // end
      infoMsgFarsi: '',
      infoMsgEng: '',
      infoPassMsg: '',
      serverErrMsg: '',
      cardErrMsgFarsi: '',
      cardErrMsgEng: '',
      nonVerEmailId: '',
      editedItem: { 
        studentId: 0,
        name: '',
        naam: '',
        code: '',
        email: ''

      }, 
      email: '',

      emailRules: [ 
        v => { 
          if ( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
            this.disForgotPass = false
            return true
          }
          this.disForgotPass = true
         return 'E-mail must be valid' 
        }
      ],
      passwordRules: [
        v => {
          if (v) {
            return true
          } else {
            return false
          }
        }
      ],
      isFormValid: false,
      registValid: false,
      disForgotPass: true,
      imageData: null,
      password: '',
      backendHost: process.env.NODE_ENV === 'development' ? 'http://localhost:3085'  : ''
    }
  },
  watch: {
  },
  async mounted() {
   //console.log("KJKJKJKJ")
   //const res = (await axios.get('http://localhost:3085/users'))
   //  console.log(res)
   //  this.students = res['data']
   //console.log(res['data'])
   

  },
  computed: {
    ...Vuex.mapState([
      "rtl",
    ]),
    accessToken:{
      get: function(){
        return this.$store.state.accessToken;
      },
      set: function(newToken){
        this.$store.commit('accessToken',newToken);
      }
    },
    emailLabel() {
      if (this.rtl)
        return "پست الکترونیک"
      else
        return "email"
    },
    passwdLabel() {
      if (this.rtl)
        return "رمز ورود" 
      else
        return "password"
    },
    nameEnLabel() {
      if (this.rtl)
        return "نام"  
      else
        return "First Name"
    },
    lastNameEnLabel() {
      if (this.rtl)
        return "نام خانوادگی " 
      else
        return "Last Name"
    },
    nameFaLabel() {
      if (this.rtl)
        return "نام (فارسی)"
      else
        return "First Name (Farsi)"
    },
    lastNameFaLabel() {
      if (this.rtl)
        return "نام خانوادگی (فارسی)"
      else
        return "Last Name (Farsi)"
    },
    

  },
  methods: { 
    ...Vuex.mapActions("auth", [
      "login","register"
    ]),
    showPassResetMeth() {
      this.showRegisterDialog= false
      this.showErrCard= false
      this.showRegInfCard= false
      this.showLoginCard= false
      this.showPassRstCard = true
      //this.infoPassMsg = true
    },
    showLoginCardMeth() {
      this.showRegisterDialog= false
      this.showErrCard= false
      this.showRegInfCard= false
      this.showLoginCard= true
      this.showPassRstCard = false
      //this.infoPassMsg = true
    },
    showErrCardMeth() {
      this.showRegisterDialog= false
      this.showErrCard= true
      this.showRegInfCard= false
      this.showLoginCard= false
      this.showPassRstCard = false
      //this.infoPassMsg = true
    },
    async sendPasswordResetLink() {
      await axios.get(`${this.backendHost}/api/students/0/password_reset?email=${this.email}`, this.headerConfig)
      this.showInfoCardMeth('show-obscure-pass-reset')
    },
    showInfoCardMeth( type) {
      
      if (type === 'show-reg-success-info') {
        this.infoMsgFarsi =
          "ثبت نام شما با موفقیت انجام شد. لطفا به ایمیل ثبت نام مراجعه نموده و لینک داخل آن را دنبال کنید."
        this.infoMsgEng =
          "You have successfully registered on Shekkar. Next go to your email and click the confirmation link."
      } else if (type === 'show-obscure-pass-reset') {
        this.infoMsgFarsi =
          'برای بازیافت رمز ورود به شما امیلی‌ فرستاده شد'
        this.infoMsgEng =
          "We have sent an email to you for recovery password."
      }
      this.showRegisterDialog= false
      this.showErrCard= false
      this.showRegInfCard= true
      this.showLoginCard= false
      this.showPassRstCard = false 
    },
    async goToSchool(email, code) {
      console.log(email)
      console.log(code)
      const res = await this.login({ code, email })
      //const res = (await axios.post(`${state.backendHost}/login`, {email,code,kelasId}, getters.headerConfig))
      console.log(res)
      if (res.status != 200) {
        this.serverErrMsg = res.data.error
        if ( this.serverErrMsg === "EMAIL-NOT-VERIFIED" ) {
          this.cardErrMsgFarsi =
            'پست الکترونیک شما تائید نشده است'
          this.cardErrMsgEng =
            "Your email is not confirmed"
          this.showErrCardMeth()
          this.nonVerEmailId = res.data.id
        } else if ( this.serverErrMsg === 'AUTH-FAIL') {
          this.cardErrMsgFarsi =
            'ایمیل یا کلمه رمز شما صحیح نیست'
          this.cardErrMsgEng =
            "Email or password not correct!"
        }
          this.showErrCardMeth()
      } else {
        await this.$router.push({ name: 'darses', params: { darsId: 'latest' } })
      }

    },
    async openRegDialog () {
      this.showRegisterDialog = true
      //this.$vuetify.rtl = false
    },
    async resendEmail () {
      await axios.get(`${this.backendHost}/api/students/${this.nonVerEmailId}/send_email`,  this.headerConfig)
    },
    async submitReg() {
      //this.editedItem.needToSave = true
      const student = this.editedItem
      //const kelasIds = student.kelases.map(k => this.kelases.find(j => j.kelasName === k )).filter(k=>k)
      //student.kelases = kelasIds 
      if (this.imageData) {
        student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
      }
      //const res = await axios.post(`${this.backendHost}/api/students?register=true`, student, this.headerConfig)
      const res = await this.register(student)

      console.log(res.status)
      if (res.status === 200) {
        //this.students.push(this.editedItem)
        //this.students = Object.assign({}, this.students )
        
        this.showInfoCardMeth('show-reg-success-info')
      } else {
        this.showErrCard = true
        this.serverErrMsg = res.data.error
        this.cardErrMsgFarsi = 
          "متاسفانه ثبت نام شما با اشکال مواجه شد. لطفا به پشتیبانی‌ ایمیل بفرستید" +
          "   (support@shekkar.net)  "
        this.cardErrMsgEng = 
          "Your registration was not successful. Please contact Shekkar support at support@shekkar.net"
      }

      this.showRegisterDialog = false
      //this.$vuetify.rtl = true
    },
    async close() {
      this.showRegisterDialog = false
      //this.$vuetify.rtl = true

    }

    //studentRoute(student) {
    //  return `/dars/${this.students[student]}?student=${student}`
    //}
  }
}
</script>
<style>
.disable-events {
  pointer-events: none,
}
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
a,a:visited { 
    text-decoration: none;
    color: red;
}


</style>
