<template>

<v-container class="fill-height">
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
    
      <v-card v-if="showSuccess" class="elevation-12">
        <v-card-title>
          {{ this.rtl ? 
          "پست الکترنیک شما تائید شد"
          :
          "Your email is successfully verified"
           }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium color="#64B5F6"  href="/ui/login">
            {{ 
              this.rtl ?
               "وارد شوید"
               : 
               "Enter Shekkar"
              }}
          </v-btn>
        </v-card-actions>
      </v-card>
      
<v-card v-else class="elevation-12">
        <v-card-title>
          {{ this.rtl ?
          "متاسفانه پست الکترونیک شما تائید نشد"
          :
          "Unfortunately your email could not be verified."
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium color="#64B5F6"  href="/ui/login">
            {{ this.rtl ?
            "بازگشت"
            :
            "RETURN"
          }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
          </v-row>
          </v-container>

</template>

<script>

import axios from 'axios'
import Vuex from 'vuex'

export default {
  name: 'UserVerif',
  data: function () {
    return {
      dialog: false,
      accessCode: '',
      showSuccess: false,
      showError: false,
      backendHost: process.env.NODE_ENV === 'development' ? 'http://localhost:3085'  : ''
    }
  },
  watch: {
  },
  async mounted() {
    const _studentId = this.$route.params.id
    const verifCode = this.$route.query.verification_code
    const res = await axios.get(`${this.backendHost}/api/students/${_studentId}/verify?verification_code=${verifCode}`, this.headerConfig)
    if ( res.status === 200 ) {
      this.showSuccess = true
    }
    

  },
  computed: {
    ...Vuex.mapState([
      "rtl",
    ]),
   //accessToken:{
   //  get: function(){
   //    return this.$store.state.accessToken;
   //  },
   //  set: function(newToken){
   //    this.$store.commit('accessToken',newToken);
   //  }
   //},
    

  },
  methods: { 
    ...Vuex.mapActions("auth", [
      "login",
    ]),
    async openDialog () {
      this.dialog = true
      this.$vuetify.rtl = false

    },
    async save() {
      //this.editedItem.needToSave = true
      const student = this.editedItem
      //const kelasIds = student.kelases.map(k => this.kelases.find(j => j.kelasName === k )).filter(k=>k)
      //student.kelases = kelasIds 
      if (this.imageData) {
        student.profileImage = { contentType: "image/jpeg", data: this.imageData.split(',')[1] }
      }
      const res = await axios.post(`${this.backendHost}/api/students?register=true`, student, this.headerConfig)
      if (res.status === 200) {
        //this.students.push(this.editedItem)
        //this.students = Object.assign({}, this.students )
        this.students = res.data
      }
      this.dialog = false
      this.$vuetify.rtl = true
    },
    async close() {
      this.dialog = false
      this.$vuetify.rtl = true

    }

    //studentRoute(student) {
    //  return `/dars/${this.students[student]}?student=${student}`
    //}
  }
}
</script>
