import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
//import pn from 'persian-number';

Vue.use(Vuex)

const state=  {
     horoof: [],
     audioAlph: {},
     imgAlph: {},
     harfFormsHier: {},
     harfSoundsAll: {},
  }
const mutations= {
   horoof(state, horoof ) { 
      state.horoof = horoof
   },
   audioAlph(state, audioAlph ) { 
      state.audioAlph = audioAlph
   },
   imgAlph(state, imgAlph ) { 
      state.imgAlph = imgAlph
   },
   harfFormsHier(state, harfFormsHier ) { 
      state.harfFormsHier = harfFormsHier
   },
   harfSoundsAll(state,harfSoundsAll) {
      state.harfSoundsAll = harfSoundsAll
   }
  //dars(state, dars ) { 
  //   state.dars = dars
  //}
}
const actions= {
   async getHoroof({commit, rootState, rootGetters}) {
     
      let blob
      let url
      let res = await axios.get(`${rootState.auth.backendHost}/api/horoof`, rootGetters["auth/headerConfig"])
      const horoof = res.data
      commit('horoof',horoof)

      let audioAlphLoc = {}
      let imgAlphLoc = {}

      for ( let harf of state.horoof ) {
         console.log(harf.harfAudios[0])
         if ( harf.harfAudios[0].data ) {
         blob = new Blob([ new Buffer(harf.harfAudios[0].data, 'base64')], { type: 'audio/m4a' });
         url = window.URL.createObjectURL(blob)
         audioAlphLoc[harf.harfSounds[0] ]  = url
         } else {
            audioAlphLoc[harf.harfSounds[0] ]  = null 
         }
         for ( let [idx,harfImage] of harf.harfImages.entries() ) {
            blob = new Blob([ new Buffer(harfImage.data, 'base64')], { type: 'image/png' });
            url = window.URL.createObjectURL(blob)
            imgAlphLoc[harf.harfKeys[idx]]  = url
         }
      }
      const harfFormsHierLoc = {}
      const harfSoundsAllLoc = {}
      for (let harf of state.horoof) {
         const harfKeys = harf.harfKeys.reduce((tot, harfKey, idx) => {
            const harfSound = harf.harfSounds[idx]
            harfSoundsAllLoc[harfKey] = harfSound
            const harfGroup = harf.harfGroups[idx]
            if (harfGroup === 'self') {
               const o = {}
               o[harfKey] = []
               tot.push(o)
            } else if ( !harfKey.match(/2/) ) {
            //} else {
               const idx2 = harf.harfKeys.findIndex(hf => hf === harfGroup)
               const hf2 = harf.harfKeys[idx2]
               tot[tot.length - 1][hf2].push(harfKey)
            }
            return tot
         }, [])
         harfFormsHierLoc[harf.harfF] = harfKeys
      }
      commit('audioAlph',audioAlphLoc)
      commit('imgAlph',imgAlphLoc)
      commit('harfFormsHier',harfFormsHierLoc)
      commit('harfSoundsAll',harfSoundsAllLoc)

   },
}

const getters = {
  goToHarfReviewDarses(state,getters,rootState) {
     if ( !state ||  state.horoof.length ===0 || !rootState.student || rootState.student.numHarfLearned === 0 ) return []
     const learnedHarfs = state.horoof.slice(0,rootState.student.numHarfLearned)
     const retArr = Array.from(Array(learnedHarfs.length).keys()).map( (idx,a) => { 
        return { 
          // text: `${learnedHarfs[a].harfF} - ${pn.convertEnToPe(idx)}`,
           text: `${learnedHarfs[a].harfF}`,
           value: idx + 1 
        }
     })
     // manually adjust for the only exception
     //retArr[0].text = `${learnedHarfs[0].harfF},${learnedHarfs[1].harfF} - ${pn.convertEnToPe(1)}`
     retArr.shift()
     retArr[0].text = `${learnedHarfs[0].harfF},${learnedHarfs[1].harfF}`
      return retArr
    
  },
}

export default{
   namespaced: true,
   state,
   actions,
   mutations,
   getters,
}

