<template>
   <v-container >
            <canvas  id="allDarsCanvas" class="mx-0"  style="'border-top': 1px solid red; float: center">
            </canvas>
      <v-row id="row1"  class="col-12" >
         <v-col sm="12"   >
            <!--<img id="ear" style="display:none" src="images/ear.jpg" >  -->
            <div  id="allImagesAllDars">
               <div v-for="(aG,aIdx) in horoof" :key="aIdx" >
                  <img v-for="alpha in aG['harfKeys']" :key="alpha" style="display:none"  v-bind:id="alpha" v-bind:src="imgAlph[alpha]"> 
                  <img id="ear" style="display:none" src="/images/ear.jpg" > 
               </div>
            </div>
                  <div id="allAudiosAllDars">
                     <!-- to debug audios, add controls to audio tag --> 
                     <audio v-for="dars in reviewDarses" :key="dars.kalameh.kalameh" :id="'all'+dars.kalameh.kalameh"  > 
                        <source v-if="audioDarses[dars.kalameh.kalameh]" :src="audioDarses[dars.kalameh.kalameh]" type="audio/mp3">
                     </audio>
                  </div>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
const paper =  require('paper');
import {  utils }  from '../class';
//import { mdiHome, mdiChevronLeft, mdiChevronRight, mdiArrowRightBold, mdiArrowLeftBold } from '@mdi/js';
import { mdiViewQuilt, mdiChevronLeft, mdiChevronDoubleLeft, mdiChevronRight, mdiChevronDoubleRight  } from '@mdi/js';
//import lodash from 'lodash'
import pn from 'persian-number';
//import store from '../store';
import Vuex from 'vuex'
import Vue from "vue";


export default {
   name: 'AllDarses',
   components: {
   },
   data: function () {
      return {
         NUM_REVIEW_PAGE:5, 
         noRender: false,
         pageId: null,
         //darses: [],
         //horoof: [],
         selectedPageId: 1, 
         darsDone: false,
         mdiChevronRight,
         mdiChevronLeft,
         mdiChevronDoubleRight,
         mdiChevronDoubleLeft,
         mdiViewQuilt,
         canvas: null,
         goToPages: [], 
      }
   },
  beforeRouteUpdate(to,from,next) {
     utils.updateCanvasAllDarses(this.reviewDarses,this.canvas.height)
     next()
  },
  watch: {
  },
  async mounted() {

  //  console.log(this.headerConfig)
     this.changeRTL()
     const height = this.checkRes()

     await this.getStudentKelas({});
     await this.getKelas({kelasId: this.studentKelasId})

     await this.getLatestDars()
     await this.getHoroof();

     //console.log(this.$route.query)
     //const pageId = parseInt(this.$route.params.page)
     await this.getNumHarfLearned()

     //await this.getReviewDarses({pageId: this.pageId, pageSize:this.NUM_REVIEW_PAGE }) // this.$route.query.page})
     // this can have page 1 to 32
     await this.getReviewDarsesHarf({pageId: this.allDarsesPage  }) // this.$route.query.page})

    //const numPages = Math.floor(this.student.darsId/this.NUM_REVIEW_PAGE)  
    //this.goToPages = Array.from(Array(numPages).keys()).map( a => { 
    //   return { 
    //      text: `برو به صفحه  ${this.pn(a+1)}`,
    //      value: a+1
    //   }

    //})
     await this.initCanvas(height)
     //this.$emit('darsId', this.darsId )
     //const canvasWait = 1000
     //setTimeout ( () => { 
     //}, canvasWait) 
     // instead of timeout try nextTick
     Vue.nextTick(() => {
        utils.updateCanvasAllDarses(this.reviewDarses,this.canvas.height)
     }) 
  },
  computed: { 

     ...Vuex.mapState("student",[ 
        "numHarfLearned",
        "kelasDarsId",
        "student",
        "currDarsId",
        "currDars",
        "studentKelasId",
        "reviewDarses",
        "audioDarses",
        "currReviewPageId"
    ]),
     ...Vuex.mapState("harf",[ 
        "horoof",
        "audioAlph",
        "imgAlph",
     ]),
     ...Vuex.mapState("kelas",[ 
        "kelas",
        ]),
     ...Vuex.mapGetters("student",[
        "currDarsNumHarf",
        'darsKalameh',
        'darsHoroof',
        'darsHarfKeys',
        // ...
     ]),
     allDarsesPage() {
        if (this.currDarsNumHarf > 1 ) {
           if ( this.currDars.darsType == "HARF_START") {
               return this.currDarsNumHarf
           } else { 
               return this.currDarsNumHarf -1; 
           }
        } else { 
           return 1
         }
     },


   lastPage() {
     const numPages = Math.floor(this.student.darsId/this.NUM_REVIEW_PAGE)  
     return numPages
   },
  },
  //
  methods: {
     ...Vuex.mapActions("student",[
        "getStudentKelas",
        "getDars",
        "setDarsId",
        "getNumHarfLearned",
  //      "getReviewDarses"
        "getReviewDarsesHarf"
     ]),
     ...Vuex.mapActions("harf",[
        "getHoroof",
     ]),
     ...Vuex.mapActions("kelas",[
        "getKelases",
        "getKelas",
     ]),
     async getLatestDars() {
        this.getDars({darsId: "latest"})
        //this.addDarsAudio();
     },

     checkRes() {
        const canvOff = 120
       //if ( window.innerWidth < 800 ) {    
       //   this.noRender = true
       //   return
       //}
        console.log("Window inner Height: " + window.innerHeight + ", inner Width: " + window.innerWidth)
        const height = window.innerHeight - canvOff 
        console.log(`Set Canvas Height to innerHeight -150: ${height}`)
        return height
     },
    changeRTL () {
      this.$vuetify.rtl = true
    },

    
    pn(num) {
      //return pn.convert(num)
      return pn.convertEnToPe(num)
    },
    async initCanvas(height) {
      this.canvas = document.getElementById('allDarsCanvas');
      this.canvas.style.backgroundColor = 'white'
      this.canvas.height =  height; // window.innerHeight
      this.canvas.width = this.canvas.parentElement.clientWidth - 10; // window.innerHeight
      console.log("canvas width: " + this.canvas.width )
      console.log("canvas height: " + this.canvas.height)
      await paper.setup(this.canvas);
      window.paper = paper
      // Make the canvase draggable but not needed 12/14/22
      ////   var toolPan = new paper.Tool()
      ////   toolPan.activate()
      //// // On drag, scroll the View by the difference between mousedown
      //// // and mouseup
      //// toolPan.onMouseDrag = function (event) {
      ////    event.stopPropagation()
      ////    var delta = event.downPoint.subtract(event.point)
      ////    paper.view.scrollBy(0, delta.y)
      ////    event.preventDefaults()
      ////    //console.log(event)
      //// }


      // scalaing like this wont fix RtoL issue
      // const canvasContext = canvas.getContext('2d');
      // canvasContext.translate(window.innerWidth, 0);
      // canvasContext.scale(-1,1 );
      // canvasContext.save()

      //this line eliminates need to access everything through paper object
      // but as a sideeffect will impact global scope for example breaks browsersync
    },
    async goToDarses() {
      this.$router.push({name: 'main',params: {darsId:'latest'}})
    },
    async goToPage(n) { 
     this.currReviewPageId =n
    },
    
  }
}
</script>

<style scoped>
.disable-btn {
  pointer-events: none;
  opacity: 0.2;
}
a {
    text-decoration: none;
}
</style>
